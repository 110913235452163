import { Country } from 'src/api/zrm';

import { getLocale } from '../getLocale';

const formatPercent = (amount: number | string, country: Country, decimals?: number) => {
  const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

  return new Intl
    .NumberFormat(getLocale(country), {
      style: 'percent',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .format(parsedAmount);
};

export default formatPercent;
