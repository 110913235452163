import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid, Typography, Slide } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { LoanPurposeNO, LoanPurposeSE } from 'src/api/zrm';
import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import loanPurposesByCountry from 'src/defaults/loanPurpose/loanPurposesByCountry';
import formatCurrency from 'src/utils/format/formatCurrency';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


import RefinanceAmountField from './RefinanceAmountField';

interface RefinanceFormFieldProps {
  name: string;
}


const RefinanceFormField: FC<RefinanceFormFieldProps> = (props) => {
  const { name: refinanceName } = props;
  const newLoanPurposeName = 'newLoanPurpose';
  const refinanceAmountName = 'refinanceAmount';

  const { t } = useTranslation();
  const { country } = useSettingsContext();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();

  const [otherPurposeSelected, setOtherPurposeSelected] = useState(false);

  const shouldRefinance = getValueByPath<boolean>(values, refinanceName);
  const refinanceError = getValueByPath<string>(errors, refinanceName);
  const refinanceIsTouched = getValueByPath<boolean>(touched, refinanceName);

  const newLoanPurpose = getValueByPath<LoanPurposeSE | LoanPurposeNO>(values, newLoanPurposeName);
  const newLoanPurposeError = getValueByPath<string>(errors, newLoanPurposeName);
  const newLoanPurposeIsTouched = getValueByPath<boolean>(touched, newLoanPurposeName);
  const loanPurposes = loanPurposesByCountry[country];

  const loanAmount = getValueByPath<number>(values, 'loanAmount');
  const refinanceAmount = getValueByPath<number>(values, refinanceAmountName);
  const showNewLoanPurposeInRefinance = useMemo(() => Math.floor(loanAmount / 10_000) > Math.floor(refinanceAmount / 10_000), [loanAmount, refinanceAmount]);

  const toggleOtherPurposeSelected = () => {
    setOtherPurposeSelected(p => !p);
    setFieldValue(newLoanPurposeName, null);
    setFieldTouched(newLoanPurposeName, false, false);
  };

  useEffect(() => {
    if (loanPurposes?.otherLoanPurposes?.includes(newLoanPurpose)) { setOtherPurposeSelected(true); }
  }, [newLoanPurpose]);

  // TODO: this should not be here!
  const showLoanPurpose = useMemo(() => {
    if (shouldRefinance === false) { return true; }

    if (showNewLoanPurposeInRefinance && shouldRefinance) { return true; }

    return false;
  }, [shouldRefinance, showNewLoanPurposeInRefinance]);

  const alternativeNewLoanQuestion = useMemo(() => {
    return shouldRefinance && showNewLoanPurposeInRefinance;
  }, [shouldRefinance, showNewLoanPurposeInRefinance]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('Do you want to refinance your other loans?')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={refinanceName}
          value={shouldRefinance}
          exclusive
          onChange={(_e, v) => {
            if (v !== null) {
              setFieldValue(refinanceName, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(refinanceName, true, false);
            }
          }}
          className={(refinanceError && refinanceIsTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <StyledToggleButton value={true}>
                {t('Yes')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <StyledToggleButton value={false}>
                {t('No')}
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
        {refinanceError && refinanceIsTouched && (
          <Grid
            item
            xs={12}
            sx={{ mt: 1 }}
          >
            <Typography
              variant='caption'
              color='error'
            >
              {refinanceError}
            </Typography>
          </Grid>
        )}
      </Grid>

      {!!shouldRefinance && (
        <Grid
          item
          xs={12}

        >
          <Slide
            in={shouldRefinance}

            direction="left"
          >

            <Box>
              <RefinanceAmountField name={refinanceAmountName} />
            </Box>
          </Slide>
        </Grid >
      )}
      {showLoanPurpose && (
        <Grid
          item
          xs={12}
        >
          <Slide
            in={showLoanPurpose}
            direction="right"
          >
            <Box>
              <StyledToggleButtonGroup
                id={newLoanPurposeName}
                value={newLoanPurpose}
                exclusive
                onChange={(e, v) => {
                  if (v !== null) {
                    setFieldValue(newLoanPurposeName, v, true);
                    // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
                    setFieldTouched(newLoanPurposeName, true, false);
                  }
                }}
                className={(newLoanPurposeError && newLoanPurposeIsTouched) ? 'error' : ''}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {alternativeNewLoanQuestion ? t('What is the remaining {{amount}} be used for?', { amount: formatCurrency(loanAmount - refinanceAmount, country, 0) }) : t('What is the main purpose for the loan?')}
                    </Typography>
                  </Grid>
                  {loanPurposes.mainLoanPurposes.map((purpose) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      key={purpose}
                    >
                      <StyledToggleButton
                        value={purpose}
                        className='no-uppercase'
                      >
                        {t(`loanPurpose-${purpose}`)}
                      </StyledToggleButton>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={6}
                    md={4}
                  >
                    <Button
                      variant="outlined"
                      onClick={toggleOtherPurposeSelected}
                      sx={{
                        width: '100%',
                        height: '100%',
                        color: otherPurposeSelected ? 'primary.main' : 'inherit',
                        borderColor: otherPurposeSelected ? 'primary.main' : 'rgba(0, 0, 0, 0.12)',
                        textTransform: 'none',
                      }}
                    >
                      {t('Other')}
                    </Button>
                  </Grid>
                  {otherPurposeSelected && loanPurposes.otherLoanPurposes.map((purpose) => (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      key={purpose}
                    >
                      <StyledToggleButton
                        value={purpose}
                        className='no-uppercase'
                      >
                        {t(`loanPurpose-${purpose}`)}
                      </StyledToggleButton>
                    </Grid>
                  ))}
                  {newLoanPurposeError && newLoanPurposeIsTouched && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant='caption'
                        color='error'
                      >
                        {newLoanPurposeError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <FieldChangeHandler name={newLoanPurposeName} />
              </StyledToggleButtonGroup>
            </Box>
          </Slide>
        </Grid>
      )}
    </Grid >

  );
};


export default RefinanceFormField;
