import { FC, ReactNode, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { MaritalStatus } from 'src/api/zrm';
import DevTools from 'src/components/DevTools/DevTools';
import YearlyIncomeField from 'src/components/fields/Income/YearlyIncomeField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import MortgageNorwayForm from 'src/types/MortgageNorway/form/MortgageNorwayForm';

interface MortgageNorwayIncomeAmountSectionProps {
  coApplicant?: boolean;
}

const MortgageNorwayIncomeAmountSection: FC<MortgageNorwayIncomeAmountSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useMortgageDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const showSpouseIncome = useMemo(() => [MaritalStatus.Married, MaritalStatus.Partner].includes(formData.family.maritalStatus) && !coApplicant, [formData.family.maritalStatus]);

  const validationSchema = useMemo(() => {
    const schema: { yearlyIncome: any; spouseYearlyIncome?: any } = {
      yearlyIncome: schemaFields.income.yearlyIncome,
    };

    if (showSpouseIncome) {
      schema.spouseYearlyIncome = schemaFields.income.spouseYearlyIncome;
    }

    return Yup.object(schema);
  }, [schemaFields, formData, showSpouseIncome]);

  const initialValues = useMemo(() => {
    const data = formData as MortgageNorwayForm;

    return !coApplicant ? {
      yearlyIncome: data.income.yearlyIncome,
      spouseYearlyIncome: data.income.spouseYearlyIncome,
    } : {
      yearlyIncome: data.coApplicant.income.yearlyIncome,
    };
  }, [formData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => {
            updateFormField(!coApplicant ? 'income.yearlyIncome' : 'coApplicant.income.yearlyIncome', values.yearlyIncome);

            if (!coApplicant) {
              updateFormField('income.spouseYearlyIncome', values.spouseYearlyIncome);
            }
          }}
          heading={!coApplicant ? 'What is your yearly income?' : 'What is your co-applicant\'s yearly income?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <YearlyIncomeField
                name='yearlyIncome'
                desc={!coApplicant
                  ? 'Enter fixed income <strong>before</strong> tax per year (e.g. salary, commission, pension). <strong>Exclude</strong> bonus or rental income.'
                  : 'Provide your co-applicant\'s fixed income <strong>before</strong> tax per year (e.g. salary, commission, pension). <strong>Exclude</strong> bonus or rental income.'
                }
              />
            </Grid>
            {showSpouseIncome && (
              <Grid
                item
                xs={12}
              >
                <YearlyIncomeField
                  name='spouseYearlyIncome'
                  desc="Partner's annual income <strong>before</strong> tax"
                />
              </Grid>
            )} 
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};

export default MortgageNorwayIncomeAmountSection;
