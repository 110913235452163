import { FC } from 'react';

import { Box, Step, StepLabel, Stepper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import SingleStep from 'src/types/Stepper/SingleStep';

import FlexColumnCenterBox from '../boxes/FlexColumnCenterBox';

const StyledStepper = styled(Stepper)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  boxShadow: '0 8px 8px -8px gray',
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
}));

interface FormStepperProps {
  steps: SingleStep[];
}

const FormStepper: FC<FormStepperProps> = (props) => {
  const { steps } = props;

  const { t } = useTranslation();

  const { currentStep, stepTitles } = useFormStepperDialogContext();

  return (
    <FlexColumnCenterBox>
      <StyledStepper
        activeStep={currentStep}
        alternativeLabel
      >
        {stepTitles.map((stepTitle) => (
          <Step key={stepTitle}>
            <StepLabel sx={{ wordBreak: 'break-word', '& .MuiStepLabel-label': { fontSize: '0.8rem' } }}>{t(stepTitle)}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
      <Box sx={{ width: '100%', '& .MuiSlider-root': { width: 'calc(100% - 24px)', mx: '12px' } }}>
        <Box sx={{ p: 3 }}>
          {steps[currentStep].component}
        </Box>
      </Box>
    </FlexColumnCenterBox>
  );
};


export default FormStepper;
