import { FC } from 'react';

import { Grid } from '@mui/material';


import ExternallyVerifiedCreditExtra from 'src/types/GR/ExternallyVerifiedCreditExtra';

import GrCreditBoxMobile from './GrCreditBoxMobile';


interface GrCreditMobileListProps {
  credits: ExternallyVerifiedCreditExtra[];
}

const GrCreditMobileList: FC<GrCreditMobileListProps> = (props) => {
  const { credits } = props;

  return (
    <Grid
      container
      spacing={2}
      sx={{ px: -2 }}
    >
      {credits?.length && credits.map((credit) =>
        <Grid
          item
          xs={12}
          key={credit.uuid}
        >
          <GrCreditBoxMobile credit={credit} />
        </Grid>
      )}
    </Grid>
  );
};


export default GrCreditMobileList;
