import { FC, ReactNode, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import HasRentalIncomeQuestionField from 'src/components/fields/Income/HasRentalIncomeQuestionField';
import YearlyIncomeField from 'src/components/fields/Income/YearlyIncomeField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import MortgageNorwayForm from 'src/types/MortgageNorway/form/MortgageNorwayForm';

interface MortgageNorwayRentIncomeAmountSectionProps { }

const MortgageNorwayRentIncomeAmountSection: FC<MortgageNorwayRentIncomeAmountSectionProps> = () => {
  const { formData, updateFormField } = useMortgageDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    hasYearlyRentIncome: schemaFields.income.hasYearlyRentIncome,
    yearlyRentIncome: schemaFields.income.yearlyRentIncome,
  });

  const initialValues = useMemo(() => {
    const data = formData as MortgageNorwayForm;

    return {
      hasYearlyRentIncome: data.income.hasYearlyRentIncome,
      yearlyRentIncome: data.income.yearlyRentIncome,
    };
  }, [formData]);


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => {
            updateFormField('income.hasYearlyRentIncome', values.hasYearlyRentIncome);
            updateFormField('income.yearlyRentIncome', values.yearlyRentIncome);
          }}
          heading={'Do you receive any rental income?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <HasRentalIncomeQuestionField name='hasYearlyRentIncome' />
            </Grid>
            {values.hasYearlyRentIncome && (
              <Grid
                item
                xs={12}
              >
                <YearlyIncomeField
                  name='yearlyRentIncome'
                  desc={'Enter the yearly rental income amount.'}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible={values.hasYearlyRentIncome} />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};

export default MortgageNorwayRentIncomeAmountSection;
