import { FC, useEffect, useMemo, useState } from 'react';

import { Container } from '@mui/material';
import { v4 } from 'uuid';

import { BodyEvStatesWithConsentMyPagesEiendomsverdieWithConsentPost, PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';
import BankIdUserData from 'src/components/auth/BankIdUserData';
import CustomerDataLoading from 'src/components/loading/CustomerDataLoading';
import { useApi } from 'src/contexts/APIContext';
import { useAuthContext } from 'src/contexts/auth/AuthContext';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import useFetchMe from 'src/hooks/fetch/useFetchMe';
import ContactData from 'src/types/auth/ContactData';

import EvFirstAppCreatedPage from './EvFirstAppCreatedPage';
import EvFirstProperties from './EvFirstProperties';
import EvFirstStartPage from './EvFirstStartPage';

interface EvFirstProps { }

const EvFirst: FC<EvFirstProps> = (props) => {
  const { } = props;
  const { checkBankIdNoAuth, authState, authFinished } = useAuthContext();
  const { appIsCreated } = useFormStepperDialogContext();
  const { setCustomerData } = useCustomerDataContext();
  const { loading: fetchMeLoading, hasEmailAndPhone, dataLoaded: meDataLoaded } = useFetchMe();
  const { api } = useApi();

  const [evDataLoading, setEvDataLoading] = useState(false);
  const [evDataLoaded, setEvDataLoaded] = useState(false);
  const [properties, setProperties] = useState<(PartialHouse | PartialCondominium | PartialVacationHome)[]>([]);

  const fetchEVData = useMemo(() => async (data?: ContactData) => {
    const requestId = v4();
    setEvDataLoading(true);

    let payload: BodyEvStatesWithConsentMyPagesEiendomsverdieWithConsentPost = { email: '', phone_number: '', marketing_consent: false };

    if (!hasEmailAndPhone) {
      payload = { email: data?.email, phone_number: data?.phone, marketing_consent: data?.marketingConsent };
    }

    try {
      const resp = await api.myPages.evStatesWithConsentMyPagesEiendomsverdieWithConsentPost(
        payload, { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setProperties((resp.data.properties) || []);
      setCustomerData(resp.data.customer);
    } catch (e) {
      console.error(e);
    }

    setEvDataLoading(false);
    setEvDataLoaded(true);
  }, [api, hasEmailAndPhone]);

  useEffect(() => {
    checkBankIdNoAuth();
  }, []);

  useEffect(() => {
    if (authState?.authenticated && hasEmailAndPhone) {
      fetchEVData();
    }
  }, [authState?.authenticated, hasEmailAndPhone]);

  const loading = fetchMeLoading || evDataLoading;

  if (!authFinished) {
    return <></>;
  }

  return (
    <>
      {authState?.authenticated && (
        <>
          {appIsCreated && (
            <EvFirstAppCreatedPage />
          )}
          {!appIsCreated && (
            <>
              {loading && (
                <CustomerDataLoading />
              )}
              {!loading && evDataLoaded && hasEmailAndPhone && (
                <EvFirstProperties properties={properties} />
              )}
              {!loading && meDataLoaded && !hasEmailAndPhone && (
                <BankIdUserData
                  callback={(data: ContactData) => {
                    fetchEVData(data);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      {!authState?.authenticated && (
        <Container
          maxWidth='sm'
          disableGutters
        >
          <EvFirstStartPage />
        </Container>
      )}
    </>
  );
};


export default EvFirst;
