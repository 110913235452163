import { LoanType } from 'src/api/zrm';
import MortgageNorwayForm from 'src/types/MortgageNorway/form/MortgageNorwayForm';

import MortgageNorwayConstants from './constants';
const defaultAmount = MortgageNorwayConstants.defaultAmount;
const defaultRepaymentTime = MortgageNorwayConstants.defaultRepaymentTime;

const MortgageNorwayFormDefaultData: MortgageNorwayForm = {
  startPage: {
    consent: false,
    loanAmount: defaultAmount,
    newLoanPurpose: null,
    refinance: null,
    refinanceAmount: defaultAmount,
    repaymentTime: defaultRepaymentTime,
  },
  family: {
    children: null,
    maritalStatus: null,
  },
  housing: {
    livingCost: null,
    livingSituation: null,
  },
  income: {
    employmentType: null,
    yearlyIncome: null,
  },
  contact: {
    email: '',
    phone: '',
  },
  pni: {
    pni: '',
  },

  coApplicant: {
    hasCoApplicant: null,
    coApplicantRelationship: null,
    family: {
      children: null,
      maritalStatus: null,
    },
    housing: {
      livingCost: null,
      livingSituation: null,
    },
    income: {
      employmentType: null,
      yearlyIncome: null,
    },
    contact: {
      email: '',
      phone: '',
    },
    pni: {
      pni: '',
    },
    loans: {
      carLoan: {
        hasLoan: null,
        monthlyPayment: null,
        rate: null,
        totalAmount: null,
        loanType: LoanType.CAR_LOAN,
        resposibility: null,
      },
      mortgageLoan: {
        hasLoan: null,
        monthlyPayment: null,
        rate: null,
        totalAmount: null,
        loanType: LoanType.MORTGAGE,
        resposibility: null,
      },
      otherLoan: {
        hasLoan: null,
        monthlyPayment: null,
        rate: null,
        totalAmount: null,
        loanType: LoanType.OTHER,
        resposibility: null,
      },
    },
  },
  loans: {
    carLoan: {
      hasLoan: null,
      monthlyPayment: null,
      rate: null,
      totalAmount: null,
      loanType: LoanType.CAR_LOAN,
      resposibility: null,
    },
    mortgageLoan: {
      hasLoan: null,
      monthlyPayment: null,
      rate: null,
      totalAmount: null,
      loanType: LoanType.MORTGAGE,
      resposibility: null,
    },
    otherLoan: {
      hasLoan: null,
      monthlyPayment: null,
      rate: null,
      totalAmount: null,
      loanType: LoanType.OTHER,
      resposibility: null,
    },
  },
};

export default MortgageNorwayFormDefaultData;
