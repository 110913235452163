import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import FieldSection from 'src/components/FieldSection';
import CurrencyFieldSuffix from 'src/types/Fields/CurrencyFieldSuffix';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

import CurrencyField from '../base/currency/CurrencyField';

interface LivingCostFieldProps {
  name: string;
  coApplicant?: boolean;
}

const LivingCostField: FC<LivingCostFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { values, errors, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection
      description={!coApplicant
        ? 'Rent, common, municipal, and operating costs. <strong>No</strong> mortgage. <strong>Only your half</strong> if you share.'
        : 'Enter your co-applicant\'s monthly housing costs (half if shared with another person).'
      }
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <CurrencyField
          value={value}
          name={name}
          error={!!error && isTouched}
          suffix={CurrencyFieldSuffix.KR_PER_MONTH_YOUR_SHARE}
          coApplicant={coApplicant}
          fullWidth
        />
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
    </FieldSection>
  );
};


export default LivingCostField;
