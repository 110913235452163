import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import EmployerNameField from 'src/components/fields/Income/EmployerNameField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface MortgageEmployerNameSectionProps {
  coApplicant?: boolean;
}

const MortgageEmployerNameSection: FC<MortgageEmployerNameSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useMortgageDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object({
    employerName: schemaFields.income.employerName,
  });

  const initialValues = {
    employerName: !coApplicant ? formData.income.employerName : formData.coApplicant.income.employerName,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormField(!coApplicant ? 'income.employerName' : 'coApplicant.income.employerName', values.employerName)}
          heading={!coApplicant ? 'What is the name of your employer?' : 'What is the name of your co-applicant\'s employer?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <EmployerNameField
                name="employerName"
                coApplicant={coApplicant}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};

export default MortgageEmployerNameSection;
