import { FC, useMemo } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Country } from 'src/api/zrm';
import FlexSpaceBetweenBox from 'src/components/boxes/FlexSpaceBetweenBox';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import { useSharedDataContext } from 'src/contexts/SharedDataContext';
import defaultInterestRates from 'src/defaults/calc/defaultInterestRates';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import calculateMonthlyPayment from 'src/utils/calc/calculateMonthlyPayment';
import formatCurrency from 'src/utils/format/formatCurrency';

interface GrLoanInfoProps { }

const GrLoanInfo: FC<GrLoanInfoProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { country, product } = useSettingsContext();
  const { values } = useFormikContext<{ [key: string]: any; }>();
  const { formData } = useSharedDataContext();

  const loanData = useMemo(() => {
    const data = formData as BlancoNorwayForm;
    const loanAmount = values.newLoan ? data.startPage.loanAmount + (values.newLoanAmount || 0) : data.startPage.loanAmount;
    const repaymentTime = data.startPage.repaymentTime;

    return {
      loanAmount: loanAmount,
      monthlyPayment: calculateMonthlyPayment(loanAmount, defaultInterestRates[product][country].nominal, repaymentTime),
    };
  }, [formData, values?.newLoan, values?.newLoanAmount]);

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <FlexSpaceBetweenBox>
          <Typography>
            {t('Total loan amount:')}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {formatCurrency(loanData.loanAmount, Country.NO, 0)}
          </Typography>
        </FlexSpaceBetweenBox>
      </Grid>
      <Grid
        item
        xs={12}
      > <FlexSpaceBetweenBox>
          <Typography>
            {t('Monthly payment:')}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {formatCurrency(loanData.monthlyPayment, Country.NO, 0)}
          </Typography>
        </FlexSpaceBetweenBox>
      </Grid>
    </Grid>
  );
};

export default GrLoanInfo;
