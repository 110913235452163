import { FC, ReactNode } from 'react';

import { Divider, Grid, Link, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';

import { Country } from 'src/api/zrm';
import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import Norway from 'src/components/countrySpecific/Norway';
import DevTools from 'src/components/DevTools/DevTools';
import ConsentCheckbox from 'src/components/fields/ConsentCheckbox';
import LoanAmountField from 'src/components/fields/LoanAmountField';
import RefinanceFormField from 'src/components/fields/RefinanceFormField';
import RepaymentTimeField from 'src/components/fields/RepaymentTimeField';
import ContinueWithFormButton from 'src/components/FormEntry/common/ContinueWithFormButton';
import MonthlyPaymentInfo from 'src/components/FormEntry/common/MonthlyPaymentInfo';
import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import formatCurrency from 'src/utils/format/formatCurrency';
import formatPercent from 'src/utils/format/formatPercent';

interface MortgageSharedStartPageSectionProps {
}

const MortgageSharedStartPageSection: FC<MortgageSharedStartPageSectionProps> = () => {

  const { formData, updateFormSection } = useMortgageDataFormContext();
  const { schemaFields } = useYupSchemaContext();
  const validationSchema = Yup.object().shape({
    loanAmount: schemaFields.startPage.loanAmount,
    repaymentTime: schemaFields.startPage.repaymentTime,
    refinance: schemaFields.startPage.refinance,
    refinanceAmount: schemaFields.startPage.refinanceAmount,
    newLoanPurpose: schemaFields.startPage.newLoanPurpose,
    consent: schemaFields.startPage.consent,
  });

  return (
    <> 
      <Formik
        initialValues={formData.startPage}
        onSubmit={() => { }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      > 
        {({ values }): ReactNode => (
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <LoanAmountField name='loanAmount' />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mb: 3 }}
            >
              <RepaymentTimeField name='repaymentTime' />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mb: 2 }}
            >
              <MonthlyPaymentInfo />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <RefinanceFormField name='refinance' />
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <ConsentCheckbox name='consent' />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox>
                <ContinueWithFormButton
                  updateFormContextCallback={() => updateFormSection('startPage', values)}
                  name='next-step'
                />
              </FlexCenterBox> 
            </Grid>
            <Norway>
              <Grid
                item
                xs={12}
                sx={{ mb: 2 }}
              >
                <Typography sx={{ fontSize: 'small' }}>
                  {/* eslint-disable react/no-unescaped-entities */}
                  <Trans>
                  By clicking "Next Step" you agree to our
                    {' '}
                    <Link
                      href="https://www.zensum.no/brukervilkar"
                      target="_blank"
                      rel="noreferrer"
                    >
                    Terms of Service
                    </Link>
                    {' '}
                  and
                    {' '}
                    <Link
                      href="https://www.zensum.no/personvern"
                      target="_blank"
                      rel="noreferrer"
                    >
                    Privacy Policy
                    </Link>
                  </Trans>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography sx={{ fontSize: 'small' }}>
                  <Trans>
                  Example calculation: An {{ loanYears:25 }}-year loan of 
                    <strong> <>{{ loanAmount: formatCurrency(2000000, Country.NO, 0) }}</></strong> with a variable interest rate of 
                    <strong> <>{{ interestRate:formatPercent(0.0747, Country.NO, 2) }}</></strong> gives an APR of 
                    <strong> <> {{ apr: formatPercent(0.0777, Country.NO, 2) }}</></strong>, 
                  a cost of <strong> <>{{ cost: formatCurrency(2436540, Country.NO, 0) }}</></strong> and total cost 
                    <strong> <>{{ totalCost: formatCurrency(4436540, Country.NO, 0) }}</></strong>.<br/>
                  Start fee / invoicing fee {{ minInvoicingFee: 1 }}-{{ maxInvoicingFee: formatPercent(0.04, Country.NO, 0) }}. 
                  The interest rate is determined individually. Updated {{ updated: '2024-10-09' }} .
                  </Trans>
                </Typography>
              </Grid>
            </Norway>
            <DevTools />
          </Grid>
        )}
      </Formik>
    </>
  );
};


export default MortgageSharedStartPageSection;
