import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';

interface MortgageSharedUcInfoProps {
}

const MortgageSharedUcInfo: FC<MortgageSharedUcInfoProps> = () => {
  const { formData } = useMortgageDataFormContext();
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
    >
      <Typography>
        {
          formData.coApplicant.hasCoApplicant
            ? (<>{t('A credit check will be conducted on you and your co-applicant when clicking "Apply now"')}</>)
            : (<>{t('A credit check will be conducted on you when clicking "Apply now"')}</>)
        }
      </Typography>
    </Grid>
  );
};

export default MortgageSharedUcInfo;