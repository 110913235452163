import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import BlancoSharedChildrenSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedChildrenSection';
import BlancoSharedLivingCostSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedLivingCostSection';
import BlancoSharedLivingSituationSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedLivingSituationSection';
import BlancoSharedMaritalStatusSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedMaritalStatusSection';
import BlancoSharedNewLoanSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedNewLoanSection';


interface BlancoNorwayFamilyAndHousingStepProps {
}

const BlancoNorwayFamilyAndHousingStep: FC<BlancoNorwayFamilyAndHousingStepProps> = (props) => {
  const { } = props;

  return (
    <FormStepperSubStepsContainer
      stepsInOrder={
        [
          <BlancoSharedNewLoanSection key='BlancoSharedNewLoanSection' />,
          <BlancoSharedMaritalStatusSection key='BlancoSharedMaritalStatusSection' />,
          <BlancoSharedChildrenSection key='BlancoSharedChildrenSection' />,
          <BlancoSharedLivingSituationSection key='BlancoSharedLivingSituationSection' />,
          <BlancoSharedLivingCostSection key='BlancoSharedLivingCostSection' />,
        ]}
    />
  );
};


export default BlancoNorwayFamilyAndHousingStep;
