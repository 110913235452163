import { FC } from 'react';

import { Grid, SxProps, Typography } from '@mui/material';

interface EvPropertyBoxMobileRowProps {
  title: string;
  value: string | number;
  titleSx?: SxProps;
  valueSx?: SxProps;
}

const EvPropertyBoxMobileRow: FC<EvPropertyBoxMobileRowProps> = (props) => {
  const { title, value, titleSx = {}, valueSx = {} } = props;

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1, py: 0.25 }}
      >
        <Typography sx={titleSx}>
          {title}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', ...valueSx }}>
          {value}
        </Typography>
      </Grid>
    </>
  );
};


export default EvPropertyBoxMobileRow;
