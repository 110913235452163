import { FC, ReactNode } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';


interface FAQSingleQuestionProps {
  title: string;
  text: string | ReactNode;
}

const FAQSingleQuestion: FC<FAQSingleQuestionProps> = (props) => {
  const { title, text } = props;

  return (
    <Box sx={{ mb: 1 }}>
      {/* <Accordion sx={{ background: 'inherit', border: 0, boxShadow: 0, '& .MuiAccordionDetails-root, & .MuiAccordionSummary-root': { px: 0 } }}> */}
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${title}-content`}
          id={`${title}-content`}
          sx={{ fontWeight: 600 }}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails sx={{ my: 0, pt: 0, pb: 1 }}>
          <Typography>
            {text}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>

  );
};

export default FAQSingleQuestion;