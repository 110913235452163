import { StrictMode } from 'react';


import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { AnalyticsContextProvider } from './contexts/AnalyticsContext';
import { APIProvider } from './contexts/APIContext';
import { AuthContextProvider } from './contexts/auth/AuthContext';
import { CustomerDataContextProvider } from './contexts/auth/CustomerDataContext';
import { LoginContextProvider } from './contexts/auth/LoginContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { SharedDataContextProvider } from './contexts/SharedDataContext';
import ThemeControlContextProvider from './contexts/ThemeControlContext';
import { ValidatorContextProvider } from './contexts/ValidatorContext';
import { YupSchemaContextProvider } from './contexts/YupSchemaContext';
import OnlineFormType from './types/OnlineFormType';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  normalizeDepth: 10,
});

const ROOT_ELEMENT_ID = process.env.REACT_APP_ROOT_ELEMENT_ID || 'online-forms';

const root = ReactDOM.createRoot(
  document.getElementById(ROOT_ELEMENT_ID)
);

const rootElement = document.getElementById(ROOT_ELEMENT_ID);
const formType = rootElement?.getAttribute('data-formType') || OnlineFormType.BlancoSweden;
const language = rootElement?.getAttribute('data-language') || 'en';
const enableDevTools = rootElement?.getAttribute('data-devTools') === 'true';
const grFirst = rootElement?.getAttribute('data-grFirst') === 'true';
const evFirst = rootElement?.getAttribute('data-evFirst') === 'true';
const thankYouPage = rootElement?.getAttribute((`data-thank-you-page-${formType}`).toLowerCase()) || '';


root.render(
  <StrictMode>
    <AuthContextProvider>
      <APIProvider>
        <SettingsProvider
          onlineFormType={formType as OnlineFormType}
          enableDevTools={enableDevTools}
          language={language}
          grFirst={grFirst}
          evFirst={evFirst}
          thankYouPage={thankYouPage}
        >
          <CustomerDataContextProvider>
            <SharedDataContextProvider>
              <AnalyticsContextProvider>
                <ValidatorContextProvider>
                  <YupSchemaContextProvider>
                    <ThemeControlContextProvider>
                      <LoginContextProvider>
                        <App onlineFormType={formType as OnlineFormType} />
                      </LoginContextProvider>
                    </ThemeControlContextProvider>
                  </YupSchemaContextProvider>
                </ValidatorContextProvider>
              </AnalyticsContextProvider>
            </SharedDataContextProvider>
          </CustomerDataContextProvider>
        </SettingsProvider>
      </APIProvider>
    </AuthContextProvider>
  </StrictMode>
);
