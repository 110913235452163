import { FC } from 'react';

import { Box, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';


const EvConsentInfoBox: FC = () => {

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }} >
      <Trans>
        <Typography
          sx={{ m: 0, textAlign: 'justify', fontSize: '0.9rem', lineHeight: '1.4' }}
        >
          I consent to Zensum&apos;s
          {' '}
          <Link
            href="https://www.zensum.no/brukervilkar"
            target="_blank"
          >
            {'Terms of Service'}
          </Link>
          {' '}
          and to my property data being retrieved and stored in accordance with applicable law. The data is processed in line with Zensum&apos;s
          {' '}
          <Link
            href="https://www.zensum.no/personvern"
            target="_blank"
          >
            {'Privacy Policy'}
          </Link>
          {' '}
          and analyzed for better loan opportunities.
        </Typography>
      </Trans>
    </Box>
  );
};


export default EvConsentInfoBox;
