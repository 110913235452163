import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

import { jwtDecode, JwtPayload } from 'jwt-decode';


interface AuthState extends JwtPayload {
  authenticated: boolean;
  token: string;
  [key: string]: any;
}

const LS_AUTH_KEY = 'onlineFormsAuthToken';

interface AuthContextProps {
  authState: AuthState;
  setAuthState: Dispatch<SetStateAction<AuthState>>;
  checkBankIdNoAuth: () => void;
  authFinished: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  checkBankIdNoAuth: () => { },
  authState: null,
  setAuthState: () => { },
  authFinished: false,
});

export const AuthContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const [authState, setAuthState] = useState<AuthState>();
  const [authFinished, setAuthFinished] = useState(false); // Indicates if auth logic is finished, required to not show login box for a second when code is executing

  const checkBankIdNoAuth = () => {
    // Check if user is already logged in with NO BankID
    const localStorageToken = localStorage.getItem(LS_AUTH_KEY);

    if (localStorageToken) {
      try {
        const decoded = jwtDecode(localStorageToken);
        const expirationMs = decoded.exp * 1000;

        if (Date.now() > expirationMs) {
          // It's expired, removing from localstorage
          localStorage.removeItem(LS_AUTH_KEY);
        } else {
          setAuthState({ ...decoded, authenticated: true, token: localStorageToken });
        }
      } catch (e) {
        console.error(e);

        // Token is probably invalid
        localStorage.removeItem(LS_AUTH_KEY);
      }
    }
    // If not, check the URL params
    // URL Params query token is "more important" than local storage one

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const success = params.get('success');

    if (success === 'true') {
      const token = params.get('access_token');

      try {
        const decoded = jwtDecode(token);
        const expirationMs = decoded.exp * 1000;

        if (Date.now() > expirationMs) {
          // ITS EXPIRED
        } else {
          setAuthState({ ...decoded, authenticated: true, token });
          localStorage.setItem(LS_AUTH_KEY, token);
        }
      } catch (e) {
        console.error(e);
      }
    }

    setAuthFinished(true);
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        checkBankIdNoAuth,
        authFinished,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);
