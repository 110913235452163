
import { Product, Country } from 'src/api/zrm';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import BlancoNorwayConstants from 'src/defaults/BlancoNorway/constants';
import BlancoSwedenConstants from 'src/defaults/BlancoSweden/constants';
import MortgageNorwayConstants from 'src/defaults/MortgageNorway/constants';

const useGetConsts = () => {
  const { country, product } = useSettingsContext();

  if (product == Product.Mortgage) {
    return MortgageNorwayConstants;
  } else {
    if (country == Country.NO) {
      return BlancoNorwayConstants;
    } else {
      return BlancoSwedenConstants;
    }
  }

};

export default useGetConsts;