import { FC, ReactNode, useMemo } from 'react';

import { Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import NextStepULNOConsent from 'src/components/Consents/NextStepULNOConsent';
import DevTools from 'src/components/DevTools/DevTools';
import GrLoanInfo from 'src/components/fields/GrNewLoan/GrLoanInfo';
import GrNewLoanPurposeField from 'src/components/fields/GrNewLoan/GrNewLoanPurposeField';
import NewLoanQuestionField from 'src/components/fields/GrNewLoan/NewLoanQuestionField';
import LoanAmountField from 'src/components/fields/LoanAmountField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';

interface BlancoSharedNewLoanSectionProps {

}

const BlancoSharedNewLoanSection: FC<BlancoSharedNewLoanSectionProps> = (props) => {
  const { } = props;
  const { formData, updateFormSection } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    newLoan: schemaFields.grStartPage.newLoan,
    newLoanAmount: schemaFields.startPage.newLoanAmount,
    newLoanPurpose: schemaFields.grStartPage.newLoanPurpose,
  });

  const initialValues = useMemo(() => {
    const data = formData as BlancoNorwayForm;

    return {
      newLoan: data.extraData?.newLoan?.newLoan,
      newLoanAmount: data.extraData?.newLoan?.newLoanAmount,
      newLoanPurpose: data.extraData?.newLoan?.newLoanPurpose,
    };
  }, [formData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormSection('extraData.newLoan', values)}
          heading="Are you also interested in taking out a new loan?"
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <NewLoanQuestionField name='newLoan' />
            </Grid>
            {values.newLoan && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <LoanAmountField
                    name='newLoanAmount'
                    title='New Loan Amount'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <GrNewLoanPurposeField name='newLoanPurpose' />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
            >
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mb: 2 }}
            >
              <GrLoanInfo />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible />
            </Grid>
            <NextStepULNOConsent />
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};

export default BlancoSharedNewLoanSection;
