import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { v4 } from 'uuid';

import { OnlineFormEventType, OnlineFormEventSubType } from 'src/api/zrm';
import { QuickCreateBlancoApplication } from 'src/api/zrm';
import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';

import { useApi } from '../APIContext';

interface BlancoApiFormContextProps {
  saveBlancoForm: (formData: any) => Promise<boolean>;
  savingInProgress: boolean;
  savingErrorMessage: string;
}

interface BlancoApiFormContextProviderProps {
  children: ReactNode;
}

const BlancoApiFormContext = createContext<BlancoApiFormContextProps>({
  saveBlancoForm: async () => false,
  savingErrorMessage: '',
  savingInProgress: false,
});

const BlancoApiFormContextProvider: FC<BlancoApiFormContextProviderProps> = (props) => {
  const { children } = props;
  const { api } = useApi();
  const { analyticsAddEvent } = useAnalyticsContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const saveBlancoForm = async (parsedFormData: QuickCreateBlancoApplication) => {
    const requestId = v4();
    setLoading(true);
    let success = false;

    try {
      const res = await api.online.onlineQuickCreateOnlineQuickCreatePost(
        parsedFormData, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      analyticsAddEvent(OnlineFormEventType.SUBMISSION, OnlineFormEventSubType.SUCCESS, { submission_id: res?.data?.submission_id });

      success = true;
    } catch (e: any) {
      setError(e.error);
    }

    setLoading(false);

    return Promise.resolve(success);
  };

  return (
    <BlancoApiFormContext.Provider
      value={{
        saveBlancoForm,
        savingErrorMessage: error,
        savingInProgress: loading,
      }}
    >
      {children}
    </BlancoApiFormContext.Provider>
  );
};

const useBlancoApiFormContext = () => useContext(BlancoApiFormContext);

export { BlancoApiFormContextProvider, useBlancoApiFormContext };
