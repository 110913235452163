import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';


import { ApplicantNO, ApplicantSE } from 'src/api/zrm';


interface CustomerDataContextProps {
  customerData: ApplicantSE | ApplicantNO;
  setCustomerData: Dispatch<SetStateAction<ApplicantSE | ApplicantNO>>;
}

const CustomerDataContext = createContext<CustomerDataContextProps>({
  customerData: null,
  setCustomerData: () => { },
});

export const CustomerDataContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const [customerData, setCustomerData] = useState<ApplicantSE | ApplicantNO>(null);

  return (
    <CustomerDataContext.Provider
      value={{
        customerData,
        setCustomerData,
      }}
    >
      {children}
    </CustomerDataContext.Provider>
  );
};

export const useCustomerDataContext = (): CustomerDataContextProps => useContext(CustomerDataContext);
