import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { EmploymentType } from 'src/api/zrm';
import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import Norway from 'src/components/countrySpecific/Norway';
import Sweden from 'src/components/countrySpecific/Sweden';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface EmploymentTypeFieldProps {
  name: string;
  coApplicant?: boolean;
}

const EmploymentTypeField: FC<EmploymentTypeFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>
          {t(!coApplicant
            ? 'Select what best describes you.'
            : 'Select what best describes your co-applicant.'
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);

            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={EmploymentType.Full}>
                {t('Full Time')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={EmploymentType.Hourly}>
                {t('Hourly')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={EmploymentType.SelfEmployed}>
                {t('Self-Employed')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={EmploymentType.Trial}>
                {t('Trial')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={EmploymentType.Project}>
                {t('Project / Standin')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={EmploymentType.Retired}>
                {t('Retired')}
              </StyledToggleButton>
            </Grid>
            <Sweden>
              <Grid
                item
                xs={6}
                md={4}
              >
                <StyledToggleButton value={EmploymentType.EarlyRetired}>
                  {t('Early Retirement')}
                </StyledToggleButton>
              </Grid>
            </Sweden>
            <Norway>
              <Grid
                item
                xs={6}
                md={4}
              >
                <StyledToggleButton value={EmploymentType.Disabled}>
                  {t('Disabled')}
                </StyledToggleButton>
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
              >
                <StyledToggleButton value={EmploymentType.NorwegianSickLeave}>
                  {t('Norwegian Sick Leave')}
                </StyledToggleButton>
              </Grid>
            </Norway>
          </Grid>
        </StyledToggleButtonGroup>
        {error && isTouched && (
          <Grid
            item
            xs={12}
          >
            <Typography
              variant='caption'
              color='error'
            >
              {error}
            </Typography>
          </Grid>
        )}
        <FieldChangeHandler name={name} />
      </Grid>
    </Grid>
  );
};


export default EmploymentTypeField;
