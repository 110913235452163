import { FC } from 'react';

import { Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface PhoneFieldProps {
  name: string;
  fullWidth?: boolean;
}

const PhoneField: FC<PhoneFieldProps> = (props) => {
  const { name, fullWidth } = props;

  const { t } = useTranslation();
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection>
      <Grid
        item
        xs={12}
        md={fullWidth ? 12 : 6}
      >
        <TextField
          value={value}
          onChange={handleChange}
          name={name}
          label={t('Phone')}
          onBlur={handleBlur}
          error={!!error && isTouched}
          autoComplete='tel'
          inputProps={{ autocomplete: 'on', inputMode: 'tel' }}
          fullWidth
        />
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler name={name} />
    </FieldSection>
  );
};


export default PhoneField;
