import { FC } from 'react';

import { Card, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Country, PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';
import formatCurrency from 'src/utils/format/formatCurrency';

import EvPropertyBoxMobileRow from './EvPropertyBoxMobileRow';
import CondominiumIcon from './icons/CondominiumIcon';
import FarmIcon from './icons/FarmIcon';
import GarageIcon from './icons/GarageIcon';
import HouseIcon from './icons/HouseIcon';
import SemiDetachedHouseIcon from './icons/SemiDetachedHouse';
import TreesIcon from './icons/TreesIcon';
import VacationHouseIcon from './icons/VacationHomeIcon';

interface EvPropertyBoxMobileProps {
  property: PartialHouse | PartialCondominium | PartialVacationHome;
}


const EvPropertyBoxMobile: FC<EvPropertyBoxMobileProps> = (props) => {
  const { property } = props;

  const { t } = useTranslation();

  const getIcon = (estateType: string): JSX.Element => {
    switch (estateType) {
      case 'enebolig':
        return <HouseIcon />;
      case 'leilighet':
        return <CondominiumIcon />;
      case 'fritidsbolig':
        return <VacationHouseIcon />;
      case 'rekkehus':
        return <SemiDetachedHouseIcon />;
      case 'tomannsbolig':
        return <SemiDetachedHouseIcon />;
      case 'ubebygd':
        return <TreesIcon />;
      case 'landbruk':
        return <FarmIcon />;
      case 'garasje':
        return <GarageIcon />;
      default:
        return null;
    }
  };

  return (
    <Card>
      <Grid
        container
        sx={{ backgroundColor: '#e8f4ec', alignItems: 'center' }}
      >
        {property.estate_type && (
          <Grid
            item
            xs={2}
            md={1}
            sx={{ display: 'flex', justifyContent: 'left', p: 1 }}
          >
            {getIcon(property.estate_type.toLowerCase())}
          </Grid>
        )}
        <Grid
          item
          sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', p: 1 }}
          xs={10}
          md={11}
        >
          <Grid
            container
            direction={'column'}
          >
            <Grid item>
              <Typography variant='h6'>
                {property.street_address}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                {property.post_code} {property.city}
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ p: 1 }}
      >
        <EvPropertyBoxMobileRow
          title={t('Property type')}
          value={t(property.estate_type)}
        />
        <Grid
          item
          xs={12}
          sx={{ px: 1 }}
        >
          <Divider />
        </Grid>
        {property.ev_partial && (
          <EvPropertyBoxMobileRow
            title={t('Shared housing debt')}
            value={formatCurrency(property.ev_partial.last_known_common_debt, Country.NO, 0)}
          />
        )}
        <Grid
          item
          xs={12}
          sx={{ px: 1 }}
        >
          <Divider />
        </Grid>
        <EvPropertyBoxMobileRow
          title={t('Estimated value')}
          value={formatCurrency(property.ev_partial?.net_value_estimate, Country.NO, 0)}
        />
      </Grid>
    </Card >
  );
};


export default EvPropertyBoxMobile;
