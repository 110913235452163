import { FC, useEffect, useMemo, useRef } from 'react';

import { Button, Grid, Link, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';

import DevTools from 'src/components/DevTools/DevTools';

interface MortgageNorwayAppCreatedSectionProps {
}

const MortgageNorwayAppCreatedSection: FC<MortgageNorwayAppCreatedSectionProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();

  const scrollRef = useRef(null);

  const redirectLink = useMemo(() => {
    let url = 'https://minesider.zensum.no/blanco';

    if (isMobile) { url += '?login-redirect=true'; }

    return url;
  }, [isMobile]);

  // Scroll page into view due to modal scroll issue.
  // Two attempts - one when component is loaded and one after 250ms, to make sure transitions are completed
  useEffect(() => {
    scrollRef?.current?.scrollIntoView();

    setTimeout(() => {
      scrollRef?.current?.scrollIntoView();
    }, 250);
  }, []);

  return (
    <Grid
      container
      spacing={3}
      ref={scrollRef}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          sx={{ textAlign: 'center', mb: 1, color: '#aaa' }}
        >
          {t('Thank you for your loan application!')}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: '500' }}>
          {t('Share your debt information')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography>
          {t('Your application will be sent right away to our lenders if you share your debt information.')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}
      >
        <Button
          component={Link}
          variant='contained'
          href={redirectLink}
          sx={{ width: '250px' }}
        >
          {'>> '}
          {t('Share loan information')}
        </Button>
        <Link
          href={redirectLink}
        >
          <img
            src={`${process.env.PUBLIC_URL || ''}/static/gr_logo.svg`}
            height="50"
            width="auto"
            alt="gr"
          />
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography>
          {t('It takes less than a minute. The information is retrieved from the Gjeldsregisteret.')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography>
          <Trans>
            The loan amount in the application may be adjusted based on the Debt Register. After receiving loan offers from the lender, you can easily change the loan amount by contacting us at
            {' '}
            <Link
              href="tel:+4723965777"
              target="_blank"
              rel="noreferrer"
            >
              23 96 57 57
            </Link>
            {' '}
            , or by sending an email to
            {' '}
            <Link
              href="mailto:kunderservice@zensum.no"
              rel="noreferrer"
            >
              kunderservice@zensum.no
            </Link>.
          </Trans>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography>
          <Trans>
            <strong>Note!</strong>
            {' '}
            To ensure that your credit score and loan offers are not negatively affected, we recommend that you do not apply for loans with other banks or loan brokers before receiving a response from Zensum.
          </Trans>
        </Typography>
      </Grid>
      <DevTools />
    </Grid>
  );
};


export default MortgageNorwayAppCreatedSection;
