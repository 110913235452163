import { FC, ReactNode, useEffect, useState } from 'react';

import { Box, Slide } from '@mui/material';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';

interface FormStepperSubStepsContainerProps {
  stepsInOrder: ReactNode[];
}

const FormStepperSubStepsContainer: FC<FormStepperSubStepsContainerProps> = (props) => {
  const { stepsInOrder } = props;
  const isMobile = useIsMobile();
  const { currentSubStep, backwards } = useFormStepperDialogContext();

  const [currentIndex, setCurrentIndex] = useState(null);

  const [heightNeeded, setHeightNeeded] = useState(330);

  const outTime = 200;
  const inTime = outTime / (isMobile ? 0.75 : 1);
  // Gives the exiting slide time to animate out before the new slide animates in
  useEffect(() => {

    setCurrentIndex(currentSubStep);

  }, [currentSubStep]);

  return (<>

    <Box
      sx={{ minHeight: `${heightNeeded}px`, width: '100%', position: 'relative', overflow: 'hidden', transition: 'all .2s ease-in-out' }}
    >
      {stepsInOrder?.map((step, index) => {
        const current = index == currentSubStep && currentIndex == currentSubStep;

        return (

          <Slide
            easing="linear"
            in={current}

            direction={!backwards ?
              (index == currentSubStep - 1 ? 'right' : 'left')
              :
              (index == currentSubStep + 1 ? 'left' : 'right')}
            key={`step-${index}`}
            timeout={{ enter: inTime, exit: outTime }}
            mountOnEnter
            unmountOnExit
            onEnter={(e) => {
              if (e.clientHeight > heightNeeded) {
                setHeightNeeded(e.clientHeight);
              }

              e.style.position = 'absolute';
            }}
            onEntered={(e) => {
              setHeightNeeded(e.clientHeight);
              e.style.position = 'relative';
            }}

            onExit={(e) => {
              e.style.position = 'absolute';
            }}
            onExited={(e) => {
              e.style.position = 'relative';
            }}


          >
            <Box sx={{ width: '100%' }}>
              {step}
            </Box>
          </Slide>
        );
      })}
    </Box>
  </>);

};


export default FormStepperSubStepsContainer;
