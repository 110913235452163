import { FC } from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';


interface ContinueWithFormButtonProps {
  updateFormContextCallback: () => void;
  name: string;
  title?: string;
}

const ContinueWithFormButton: FC<ContinueWithFormButtonProps> = (props) => {
  const { updateFormContextCallback, name, title } = props;

  const { t } = useTranslation();
  const { openDialog } = useFormStepperDialogContext();
  const { validateForm, setTouched, values } = useFormikContext();

  const { analyticsLogFormikErrors } = useAnalyticsContext();

  const validateAndOpenDialog = async () => {
    // Need to touch all fields to show errors properly, even for fields that are not touched yet. E.g mandatory checkboxes
    setTouched(Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
    const temp = await validateForm();

    if (Object.keys(temp).length === 0) {
      updateFormContextCallback();
      openDialog({});
    } else { analyticsLogFormikErrors(temp); }

  };

  return (
    <Button
      name={name}
      variant="contained"
      onClick={() => validateAndOpenDialog()}
      endIcon={<ArrowForward />}
      sx={{ fontWeight: 'bold', width: '100%' }}
      size='large'
    >
      {title ? t(title) : t('Next step')}
    </Button>
  );
};

export default ContinueWithFormButton;
