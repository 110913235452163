import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


interface ChildrenFieldProps {
  name: string;
  coApplicant?: boolean;
}

const ChildrenField: FC<ChildrenFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection
      description={!coApplicant ? 'Indicate the number of children under 18 years old for whom you are financially responsible.' : 'Indicate the number of children under 18 years old for whom your co-applicant is financially responsible.'}
    >
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(_e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);
            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            {[0, 1, 2, 3, 4].map((i) => (
              <Grid
                item
                xs={2}
                key={`children=${i}`}
              >
                <StyledToggleButton value={`${i}`}>
                  {i}
                </StyledToggleButton>
              </Grid>
            ))}
            <Grid
              item
              xs={2}
            >
              <StyledToggleButton value={'5'}>
                5+
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler
        name={name}
        nextStepOnValueChange
      />
    </FieldSection>
  );
};


export default ChildrenField;
