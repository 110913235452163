import { FC, ReactNode, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { MaritalStatus } from 'src/api/zrm';
import DevTools from 'src/components/DevTools/DevTools';
import EmailField from 'src/components/fields/Contact/EmailField';
import PhoneField from 'src/components/fields/Contact/PhoneField';
import FieldSection from 'src/components/FieldSection';
import FinishButton from 'src/components/Stepper/common/FinishButton';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';

interface BlancoSharedContactSectionProps {
  coApplicant?: boolean;
}

const BlancoSharedContactSection: FC<BlancoSharedContactSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormSection, saveForm } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    email: schemaFields.contact.email,
    phone: schemaFields.contact.phone,
  });

  const initialValues = !coApplicant ? formData.contact : formData.coApplicant.contact;

  const showCoApplicantNorway = useMemo(() => [MaritalStatus.Married, MaritalStatus.Partner].includes(formData.family.maritalStatus), [formData?.family?.maritalStatus]);
  const isGrFirst = useMemo(() => (formData as BlancoNorwayForm)?.extraData?.isGrFirst, [(formData as BlancoNorwayForm)?.extraData?.isGrFirst]);


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormSection(!coApplicant ? 'contact' : 'coApplicant.contact', values)}
          heading={!coApplicant ? 'Share your contact details' : 'Share your co-applicant\'s contact details'}
        >
          <FieldSection
            description={
              !coApplicant
                ? 'Lenders require your contact information, and you will receive updates on your application via SMS, phone, and/or email.'
                : 'Lenders need contact details to stay in touch.'
            }
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <PhoneField name='phone' />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <EmailField name='email' />
            </Grid>
            <Grid
              item
              xs={12}
            >
              {!isGrFirst && (
                // Without GrFirst, the NextStepButton is always visible
                <NextStepButton alwaysVisible />
              )}
              {isGrFirst && coApplicant && (
                // In GrFirst flow, contact section behaviour is not changed for coApplicant
                <NextStepButton alwaysVisible />
              )}
              {isGrFirst && !coApplicant && showCoApplicantNorway && (
                // Can go to next step if co applicant is possible to add
                <NextStepButton alwaysVisible />
              )}
              {isGrFirst && !coApplicant && !showCoApplicantNorway && (
                // GrFirst flow and no possibility for adding co applicant means that customer has to send application
                <FinishButton
                  saveFormCallback={saveForm}
                  updateFormContextCallback={() => updateFormSection(!coApplicant ? 'contact' : 'coApplicant.contact', values)}
                />
              )}
            </Grid>

            <DevTools />
          </FieldSection>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};


export default BlancoSharedContactSection;
