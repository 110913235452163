import { FC } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import GrConsent from 'src/components/gr/GrConsent';
import { useLoginContext } from 'src/contexts/auth/LoginContext';

interface GrFirstStartPageProps { }

const GrFirstStartPage: FC<GrFirstStartPageProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { loginBankIdNo, loginError } = useLoginContext();

  const login = () => {
    loginBankIdNo();
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}>
          {t('Share your credit information')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <GrConsent />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <FlexCenterBox>
          <Button
            variant='contained'
            onClick={login}
          >
            {t('Retrieve My Credits')}
          </Button>
        </FlexCenterBox>
      </Grid>
      {loginError && (
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <FlexCenterBox>
            <Typography sx={{ fontSize: '0.75rem', color: 'error.main' }}>
              {t('There was an unexpected error logging in. Please try again.')}
            </Typography>
          </FlexCenterBox>
        </Grid>
      )}
    </Grid>
  );
};


export default GrFirstStartPage;
