import { FC } from 'react';

import { Grid } from '@mui/material';

import { PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';

import EvPropertyBoxMobile from './EvPropertyBoxMobile';


interface EvPropertyMobileListProps {
  properties: (PartialHouse | PartialCondominium | PartialVacationHome)[];
}

const EvPropertyMobileList: FC<EvPropertyMobileListProps> = (props) => {
  const { properties } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      {properties?.length && properties.map((property) =>
        <Grid
          item
          xs={12}
          key={property.external_id}
        >
          <EvPropertyBoxMobile property={property} />
        </Grid>
      )}
    </Grid>
  );
};


export default EvPropertyMobileList;
