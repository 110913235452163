import { FC } from 'react';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import { MortgageApiFormContextProvider } from 'src/contexts/mortgage/MortgageApiFormContext';
import { MortgageDataFormContextProvider } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useSharedDataContext } from 'src/contexts/SharedDataContext';
import MortgageNorwayFormDefaultData from 'src/defaults/MortgageNorway/MortgageNorwayFormDefaultData';

import MortgageNorwayFormStepperDialog from './MortgageNorwayFormStepperDialog';
import MortgageNorwayAppCreatedSection from './sections/MortgageNorwayAppCreatedSection';
import MortgageSharedStartPageSection from '../MortgageShared/sections/MortgageSharedStartPageSection';

interface MortgageNorwayStartPageProps { }

const MortgageNorwayStartPage: FC<MortgageNorwayStartPageProps> = (props) => {
  const { } = props;

  const { formData } = useSharedDataContext();
  const { appIsCreated } = useFormStepperDialogContext();

  return (
    <MortgageApiFormContextProvider>
      <MortgageDataFormContextProvider defaultData={MortgageNorwayFormDefaultData}>
        {formData && (
          <>
            {!appIsCreated && (
              <MortgageSharedStartPageSection />
            )}
            {appIsCreated && (
              <MortgageNorwayAppCreatedSection />
            )}
            <MortgageNorwayFormStepperDialog />
          </>
        )}

      </MortgageDataFormContextProvider>
    </MortgageApiFormContextProvider>
  );
};


export default MortgageNorwayStartPage;
