import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import MortgageSharedContactSection from 'src/forms/MortgageShared/sections/contact/MortgageSharedContactSection';
import MortgageSharedPNISection from 'src/forms/MortgageShared/sections/contact/MortgageSharedPNISection';
import MortgageSharedCoApplicantRelatiohsipSection from 'src/forms/MortgageShared/sections/familyAndHousing/MortgageSharedCoApplicantRelatiohsipSection';
import MortgageSharedLivingCostSection from 'src/forms/MortgageShared/sections/familyAndHousing/MortgageSharedLivingCostSection';
import MortgageSharedLivingSituationSection from 'src/forms/MortgageShared/sections/familyAndHousing/MortgageSharedLivingSituationSection';
import MortgageSharedMaritalStatusSection from 'src/forms/MortgageShared/sections/familyAndHousing/MortgageSharedMaritalStatusSection';
import MortgageEmployerNameSection from 'src/forms/MortgageShared/sections/income/MortgageEmployerNameSection';
import MortgageEmploymentTypeSection from 'src/forms/MortgageShared/sections/income/MortgageEmploymentTypeSection';

import MortgageNorwayIncomeAmountSection from '../income/MortgageNorwayIncomeAmountSection';

interface MortgageNorwayCoApplicantStepProps {
}

const MortgageNorwayCoApplicantStep: FC<MortgageNorwayCoApplicantStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <MortgageSharedCoApplicantRelatiohsipSection
          key='MortgageSharedCoApplicantRelatiohsipSection'
        />,
        <MortgageSharedMaritalStatusSection
          key='MortgageSharedMaritalStatusSection'
          coApplicant
        />,
        <MortgageSharedLivingSituationSection
          key='MortgageSharedLivingSituationSection'
          coApplicant
        />,
        <MortgageSharedLivingCostSection
          key='MortgageSharedLivingCostSection'
          coApplicant
        />,
        <MortgageEmploymentTypeSection
          key='MortgageEmploymentTypeSection'
          coApplicant
        />,
        <MortgageNorwayIncomeAmountSection
          key='MortgageNorwayIncomeAmountSection'
          coApplicant
        />,
        <MortgageEmployerNameSection
          key='MortgageEmployerNameSection'
          coApplicant
        />,
        <MortgageSharedContactSection
          key='MortgageSharedContactSection'
          coApplicant
        />,
        <MortgageSharedPNISection
          key='MortgageSharedPNISection'
          coApplicant
        />,
      ]}
    />
  );
};


export default MortgageNorwayCoApplicantStep;
