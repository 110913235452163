import { FC } from 'react';

import { Divider, Grid, SxProps, Typography } from '@mui/material';

interface GrCreditBoxMobileRowProps {
  title: string;
  value: string;
  titleSx?: SxProps;
  valueSx?: SxProps;
}

const GrCreditBoxMobileRow: FC<GrCreditBoxMobileRowProps> = (props) => {
  const { title, value, titleSx = {}, valueSx = {} } = props;

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 3, py: 0.25 }}
      >
        <Typography sx={titleSx}>
          {title}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'right', ...valueSx }}>
          {value}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ px: 1 }}
      >
        <Divider />
      </Grid>
    </>
  );
};


export default GrCreditBoxMobileRow;
