import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useCookies } from 'react-cookie';
import { v4 } from 'uuid';

import { OnlineFormEventData, OnlineFormEventSubType, OnlineFormEventType, OnlineFormsEvent } from 'src/api/zrm';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import { getCommitSha } from 'src/utils/getCommitSha';

import { useApi } from './APIContext';

interface AnalyticsContextProps {
  analyticsUpdateFormSteps: (step: number, substep: number) => void;
  analyticsAddEvent: (eventType: any, eventSubtype: any, eventMeta?: any) => void;
  analyticsLogFormikErrors: (errors: any) => void;
}

interface AnalyticsProviderProps {
  children?: ReactNode;
}


const AnalyticsContext = createContext<AnalyticsContextProps>({
  analyticsUpdateFormSteps: () => { },
  analyticsAddEvent: () => { },
  analyticsLogFormikErrors: () => { },
});

const collectDeviceMetadata = () => {

  const obj = {
    user_agent: window?.navigator?.userAgent,
    device_meta: { screenWidth: window?.screen?.width, screenHeight: window?.screen?.height },
    browser_lang: window?.navigator?.language,
  };

  return obj;
};
const dbg = (...args: any) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') { console.log('%c [ANALYTICS]', 'color:#3c3;font-weight:bold', ...args); }
};

export const AnalyticsContextProvider: FC<AnalyticsProviderProps> = (props) => {
  const FLUSH_EVENTS_AMOUNT = 5;
  const ZID_STORAGE_KEY = 'X-Zid';
  const ZID_COOKIE_NAME = 'zid';

  const { children } = props;
  const { analyticsApi, setFillingIdHeader } = useApi();
  const [cookies] = useCookies([ZID_COOKIE_NAME]);
  const settings = useSettingsContext();

  const [, setError] = useState(null);
  const formFillingId = useMemo<string>(() => v4(), []);
  const [events, setEvents] = useState<OnlineFormsEvent[]>([]);
  const appContainerRef = useRef<HTMLDivElement | null>(null);

  const flags = useRef({
    eventsSent: 0,
    eventsInitalized: false,
    gaFormStarted: false,
    gaFormStep: 0,
    gaFormSubStep: 0,
    gaDataChanges: 0,
    flushEvents: false,
  });


  const zid = useMemo<string>(() => {
    let zidValue = '';

    zidValue = cookies[ZID_COOKIE_NAME];

    if (!zidValue || zidValue === '') {
      zidValue = localStorage.getItem(ZID_STORAGE_KEY);
    }

    // new formFillingId means new "first event", just for more failsafe logic
    flags.current.eventsInitalized = false;

    return zidValue;
  }, [formFillingId]);

  useEffect(() => {
    setFillingIdHeader(formFillingId);
  }, [formFillingId]);


  const postEvents = useMemo(() => async (eventsList: Array<OnlineFormsEvent>) => {

    try {
      const requestId = v4();
      await analyticsApi.formAnalytics.storeEventsFormAnalyticsStoreEventsPost(
        { event_list: eventsList }, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );
      flags.current.eventsSent += eventsList.length;
    } catch (e: any) {
      setError(e.error);
    }
  }, [analyticsApi]);

  const value = useMemo(() => {
    const analyticsUpdateFormSteps = (step: number, substep: number) => {
      flags.current.gaFormStep = step;
      flags.current.gaFormSubStep = substep;
    };
    const postToGoogleAnalytics = (eventType: OnlineFormEventType, eventSubType: OnlineFormEventSubType) => {
      (window as any).dataLayer = (window as any).dataLayer || [];

      if ((window as any)?.dataLayer && [OnlineFormEventType.SUBMISSION, OnlineFormEventType.NAVIGATION, OnlineFormEventType.CLICK].includes(eventType)) {

        const dateString = new Date().toISOString().substr(0, 10);
        const eventId = `${zid}-${dateString}`;

        let gaEventName = '';
        switch (eventType) {
          case OnlineFormEventType.SUBMISSION:
            gaEventName = 'form_submit';
            break;
          case OnlineFormEventType.CLICK:

            if (!flags.current.gaFormStarted) {
              flags.current.gaFormStarted = true;
              gaEventName = 'form_start';

            }

            flags.current.gaDataChanges += 1;

            break;
          case OnlineFormEventType.NAVIGATION:
            if (eventSubType === OnlineFormEventSubType.START) {
              gaEventName = 'form_exposed';
            } else if (eventSubType === OnlineFormEventSubType.FORWARD && flags.current.gaFormStep == 0 && flags.current.gaFormSubStep == 0) {
              gaEventName = 'form_step_1';
            }

            break;
          default:
            gaEventName = '';
        }

        let gaProductName = '';

        if (settings.product === 'blanco') {
          gaProductName = 'UL';
        } else {
          gaProductName = settings.product;
        }

        let eventNumber = 0;
        switch (gaEventName) {
          case 'form_start':
            eventNumber = 1;
            break;
          case 'form_step_1':
            eventNumber = 2;
            break;
          case 'form_submit':
            eventNumber = 3;
            break;
        }


        // UL__0_0_form_exposed
        if (gaEventName != '') {
          const gaEvent = {
            event: `${gaProductName}__${eventNumber}_0_${gaEventName}`,
            event_id: eventId,
          };
          dbg('postToGoogleAnalytics', gaEvent);
          (window as any).dataLayer.push(gaEvent);
        }
      }
    };

    const addEvent = (eventType: OnlineFormEventType, eventSubType: OnlineFormEventSubType, eventMeta?: any) => {
      dbg('addEvent', eventType, eventSubType);

      /* process the input data in submission event to have submission_id at the top level */

      let submissionId = null;

      if (eventType === OnlineFormEventType.SUBMISSION && eventSubType === OnlineFormEventSubType.SUCCESS) {
        submissionId = eventMeta?.submission_id;
        eventMeta.submission_id = null;
        flags.current.flushEvents = true;
      }

      postToGoogleAnalytics(eventType, eventSubType);

      const eventData: OnlineFormEventData = {
        event_type: eventType,
        event_subtype: eventSubType,
        event_meta: { ...eventMeta, formType: settings.formType, grFirst: settings.grFirst, evFirst: settings.evFirst },
      };
      const event: OnlineFormsEvent = {
        timestamp: new Date().toISOString(),
        filling_id: formFillingId,
        submission_id: submissionId,
        zid: zid,
        data: eventData,
      };

      if (!flags.current.eventsInitalized) {
        event.domain = window?.location?.hostname;
        event.uri = window?.location?.pathname;
        event.git_sha = getCommitSha();
        event.data.device_meta = collectDeviceMetadata();
        flags.current.eventsInitalized = true;
      }

      setEvents((list) => [...list, event]);
    };


    return {
      analyticsUpdateFormSteps: analyticsUpdateFormSteps,
      analyticsAddEvent: addEvent,
      analyticsLogFormikErrors: (errors: any) => {
        if (errors) {
          Object.entries(errors).forEach(([err_key, err_value]) => {
            addEvent(OnlineFormEventType.ERROR, OnlineFormEventSubType.VALIDATION, { field: err_key, message: err_value });
          });
        }
      },
    };
  }, [formFillingId, settings.formType, settings.product]);

  /**
   * Store an event idication that the user has clicked/started entering the form.
   * UseCallback will keep reference between re-renders (until it's own dependencies change).
   */
  const addOnlineFormsInteractionEvent = useCallback(() => {
    value.analyticsAddEvent(OnlineFormEventType.CLICK, OnlineFormEventSubType.START);
    dbg('Online Forms Interaction Event');
  }, [value]);

  useEffect(() => {
    dbg('events', events);

    if (events.length === 0) {
      if (flags.current.flushEvents) {
        flags.current.flushEvents = false;
      }

      return;
    }

    const lastEvent = events[events.length - 1];
    const isInteractionEvent = lastEvent?.data?.event_type === OnlineFormEventType.CLICK && lastEvent?.data?.event_subtype === OnlineFormEventSubType.START;

    if (events.length >= FLUSH_EVENTS_AMOUNT || flags.current.flushEvents || isInteractionEvent) {
      const eventsToSend = events;
      setEvents([]);
      postEvents(eventsToSend);
      flags.current.flushEvents = false;

      if (isInteractionEvent) {
        if (appContainerRef?.current) {
          appContainerRef.current.removeEventListener('click', addOnlineFormsInteractionEvent);
        }
      }
    }
  }, [events, flags.current.flushEvents]);

  useEffect(() => {
    value.analyticsAddEvent(OnlineFormEventType.NAVIGATION, OnlineFormEventSubType.START);

    return () => {
      flags.current.flushEvents = true;
    };
  }, []);


  /**
   * Event listener for the form interaction event.
   * This will be triggered when the user clicks on the form & removed after that.
   */
  useEffect(() => {
    if (appContainerRef?.current) {
      appContainerRef.current.addEventListener('click', addOnlineFormsInteractionEvent);
    }

    return () => {
      if (appContainerRef?.current) {
        appContainerRef.current.removeEventListener('click', addOnlineFormsInteractionEvent);
      }
    };
  }, [appContainerRef?.current, addOnlineFormsInteractionEvent]);

  return (
    <AnalyticsContext.Provider value={value}>
      <Box ref={appContainerRef}>
        {children}
      </Box>
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;

export const useAnalyticsContext = () => useContext(AnalyticsContext);