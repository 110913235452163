import { FC } from 'react';

import { Grid, Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

interface NextStepULNOConsentProps { }

const NextStepULNOConsent: FC<NextStepULNOConsentProps> = () => {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ mb: 2 }}
      >
        <Typography sx={{ fontSize: 'small' }}>
          {/* eslint-disable react/no-unescaped-entities */}
          <Trans>
            By clicking "Next Step" you agree to our
            {' '}
            <Link
              href="https://www.zensum.no/brukervilkar"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
            {' '}
            and
            {' '}
            <Link
              href="https://www.zensum.no/personvern"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </Trans>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontSize: 'small' }}>
          <Trans>
            Example calculation: An 5-year annuity loan of
            {' '}
            <strong>150 000 kr</strong>
            {' '}
            with a variable interest rate of
            {' '}
            <strong>10.90%</strong>
            {' '}
            (no start fee / no invoice fee) gives an APR of
            {' '}
            <strong>11,46%</strong>
            , a total cost of
            {' '}
            <strong>195 240 kr</strong>
            , with a monthly cost of
            {' '}
            <strong>3 254 kr</strong>
            {' '}
            . Repayment period is 1–15 years for refinancing loans, and up to 5 years for new loans. Updated 2024-10-09.
          </Trans>
        </Typography>
      </Grid>
    </>
  );
};


export default NextStepULNOConsent;
