import { FC } from 'react';

import { Box } from '@mui/material';

const CreditCardIcon: FC = () => {
  return (
    <Box>
      <img
        src={`${process.env.PUBLIC_URL || ''}/static/credit-card.svg`}
        height="35"
        width="auto"
        alt="CREDIT CARD"
      />
    </Box>
  );
};


export default CreditCardIcon;
