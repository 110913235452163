import moment from 'moment';

import LengthOfStay from 'src/types/Contact/LengthOfStay';

const calculateLengthOfStay = (option: LengthOfStay): string | null => {

  switch (option) {
    case LengthOfStay.LESS_THAN_A_YEAR:
      return moment().subtract(6, 'months').format('YYYY-MM-DD');
    case LengthOfStay.ONE_TO_THREE_YEARS:
      return moment().subtract(1, 'years').subtract(1, 'days').format('YYYY-MM-DD');
    case LengthOfStay.MORE_THAN_THREE_YEARS:
      return moment().subtract(3, 'years').subtract(2, 'days').format('YYYY-MM-DD');
    default:
      return null;
  }
};

export default calculateLengthOfStay;
