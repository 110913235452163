import { FC } from 'react';

import { Container } from '@mui/material';

import FormEntryCard from 'src/components/FormEntry/FormEntryCard';
import BlancoSharedAppCreatedSection from 'src/forms/BlancoShared/sections/BlancoSharedAppCreatedSection';


interface EvFirstAppCreatedPageProps { }

const EvFirstAppCreatedPage: FC<EvFirstAppCreatedPageProps> = (props) => {
  const { } = props;

  return (
    <Container maxWidth='xs'>
      <FormEntryCard>
        <BlancoSharedAppCreatedSection />
      </FormEntryCard>
    </Container>
  );
};


export default EvFirstAppCreatedPage;
