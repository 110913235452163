import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { v4 } from 'uuid';

import { BankIDPurpose, Country, Product } from 'src/api/zrm';

import { useApi } from '../APIContext';

interface LoginContextProps {
  loginBankIdNo: () => void;
  loginError: boolean;
}

const LoginContext = createContext<LoginContextProps>({
  loginBankIdNo: () => { },
  loginError: false,
});

export const LoginContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const { api } = useApi();

  const [error, setError] = useState(false);

  const loginBankIdNo = async () => {
    setError(false);

    const requestId = v4();
    const url = new URL(window.location.href);
    let callbackUrl = url.href;

    // if (withMarketingConsent) {
    //   const paramsChar = callbackUrl.includes('?') ? '&' : '?';
    //   callbackUrl = `${callbackUrl}${paramsChar}${MARKETING_CONSENT_QUERY_PARAM_KEY}=true`;
    // }

    const failUrl = `${url.origin}`;

    try {
      const resp = await api.bankId.loginBankIdAuthPost(
        {
          country: Country.NO,
          callback_url: callbackUrl,
          callback_fail_url: failUrl,
          product: Product.Blanco,
          purpose: BankIDPurpose.SHARE_GR,
        },
        { headers: { Authorization: null, 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      window.location.replace(resp.data.login_url);
    } catch (e: any) {
      if (e?.name === 'AbortError') { return; }

      console.error(e);
      setError(true);
    }
  };

  return (
    <LoginContext.Provider
      value={{
        loginBankIdNo,
        loginError: error,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = (): LoginContextProps => useContext(LoginContext);
