import { FC } from 'react';

import { Box } from '@mui/material';

const PrivateLoanIcon: FC = () => {
  return (
    <Box>
      <img
        src={`${process.env.PUBLIC_URL || ''}/static/private-loan.svg`}
        height="35"
        width="auto"
        alt="PRIVATE LOAN"
      />
    </Box>
  );
};


export default PrivateLoanIcon;
