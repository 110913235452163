import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';

import MortgageSharedCoApplicantSectionSelector from './MortgageSharedCoApplicantSectionSelector';
import MortgageSharedContactSection from './MortgageSharedContactSection';
import MortgageSharedPNISection from './MortgageSharedPNISection';

interface MortgageSharedContactStepProps {
}

const MortgageSharedContactStep: FC<MortgageSharedContactStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <MortgageSharedContactSection key='MortgageSharedContactSection' />,
        <MortgageSharedPNISection key='MortgageSharedPNISection' />,
        <MortgageSharedCoApplicantSectionSelector key='MortgageSharedCoApplicantSectionSelector' />,
      ]}
    />
  );
};


export default MortgageSharedContactStep;
