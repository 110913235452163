import { Box, styled } from '@mui/material';

const FlexSpaceBetweenBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

export default FlexSpaceBetweenBox;
