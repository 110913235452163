import { FC, useMemo } from 'react';

import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { ApplicantNO, Country } from 'src/api/zrm';
import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import MarketingConsentBox from 'src/components/Consents/MarketingConsentBox';
import FAQSingleQuestion from 'src/components/FAQ/FAQSingleQuestion';
import GrCreditMobileList from 'src/components/gr/TableData/GrCreditMobileList';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';
import { BlancoApiFormContextProvider } from 'src/contexts/blanco/BlancoApiFormContext';
import { BlancoDataFormContextProvider } from 'src/contexts/blanco/BlancoDataFormContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import BlancoNorwayFormDefaultData from 'src/defaults/BlancoNorway/BlancoNorwayFormDefaultData';
import BlancoNorwayFormStepperDialog from 'src/forms/BlancoNorway/BlancoNorwayFormStepperDialog';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import ExternallyVerifiedCreditExtra from 'src/types/GR/ExternallyVerifiedCreditExtra';
import formatCurrency from 'src/utils/format/formatCurrency';
import formatPercent from 'src/utils/format/formatPercent';

interface GrFirstCreditsProps { }

const GrFirstCredits: FC<GrFirstCreditsProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { customerData } = useCustomerDataContext();
  const isMobile = useIsMobile();

  const { openDialog } = useFormStepperDialogContext();


  const credits = useMemo(() => (customerData as ApplicantNO)?.externally_verified_credits?.map(c => ({ ...c, uuid: v4() })) as ExternallyVerifiedCreditExtra[], [customerData]);
  const totalAmount = useMemo(() => credits?.reduce((prev, curr) => prev + curr.total_debt, 0) || 0, [credits]);

  const defaultData: BlancoNorwayForm = {
    ...BlancoNorwayFormDefaultData,
    startPage: {
      ...BlancoNorwayFormDefaultData.startPage,
      loanAmount: +totalAmount.toFixed(0),
      refinanceAmount: +totalAmount.toFixed(0),
    },
    pni: {
      pni: (customerData as ApplicantNO)?.pni,
    },
    extraData: {
      isGrFirst: true,
      newLoan: {
        newLoan: null,
        newLoanAmount: BlancoNorwayFormDefaultData.startPage.loanAmount,
        newLoanPurpose: null,
      },
    },
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={5}
      >
        <Grid
          item
          xs={12}
          sx={{ '& .MuiTypography-root': { fontWeight: 'bold' } }}
        >
          <Typography sx={{ fontSize: '2rem', mb: 1 }}>
            {t('My Credits')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>
              {t('Total Amount')}
            </Typography>
            <Typography sx={{ fontSize: '1.25rem' }}>
              {formatCurrency(totalAmount, Country.NO, 0)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>
              {t('Number of Credits')}
            </Typography>
            <Typography>
              {credits?.length || 0}
            </Typography>
          </Box>
        </Grid>
        {!credits?.length && (
          <Grid
            item
            xs={12}
          >
            <Typography>{t('You don\'t have any credits.')}</Typography>
          </Grid>
        )}
        {!!credits?.length && (
          <>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  onClick={(() => openDialog({}))}
                >
                  {t('Compare your loans')}
                </Button>
              </FlexCenterBox>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {/* {isMobile && (
              )}
              {!isMobile && (
                <GrCreditDesktopTable credits={credits} />
              )} */}
              {/* Forced mobile view until zensum.no is fixed */}
              <GrCreditMobileList credits={credits} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                  {t('Too many and/or expensive loans?')}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  onClick={(() => openDialog({}))}
                >
                  {t('Compare and consolidate loans')}
                </Button>
              </FlexCenterBox>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }}
        >
          <Typography>
            <Trans>
              The debt information is provided by Gjeldsregisteret AS. An overview of financial companies that supply debt information to Gjeldsregisteret AS can be found at
              {' '}
              <Link
                href='http://gjeldsregisteret.com/'
                target='_blank'
              >
                gjeldsregisteret.com
              </Link>
              .
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              You easily manage your consent at the
              {' '}
              <Link
                href='https://www.gjeldsregisteret.com/pages/innsyn-i-egne-opplysninger-og-administrasjon-av-samtykke'
                target='_blank'
              >
                Gjeldsregisteret
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 2 }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('Frequently Asked Questions')}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            <Trans>
              Find answers to common customer questions here. Need more help? Contact us at{' '}<Link href="callto:23965200">23 96 52 00</Link>.
            </Trans>
          </Typography>
          <FAQSingleQuestion
            title={t('How is the Credit Card Monthly Payment estimated?')}
            text={
              <Trans >
                The monthly payment is an estimate because the exact amount can vary depending on your total balance and account activity. Here&apos;s how we calculate it:

                The payment is {{ paymentPercent: formatPercent(0.15, Country.NO, 0) }} of your total balance, which includes both interest-bearing and non-interest-bearing amounts.
                If this estimated amount is less than {{ estimatedPayment: formatCurrency(1000, Country.NO) }}, the minimum payment will be {{ minimumPayment: formatCurrency(1000, Country.NO) }}.
                If your remaining balance is less than {{ remainingBalance: formatCurrency(1000, Country.NO) }}, you&apos;ll pay the full balance.
              </Trans>
            }
          />
          <FAQSingleQuestion
            title={t('What is a Non-Interest-Bearing Balance?')}
            text={
              <Trans>
                A non-interest-bearing balance is the part of your credit card balance that isn&apos;t charged interest yet.
                For example purchases during the grace period are non-interest-bearing, but if not paid by the due date, they will start to accrue interest.
              </Trans>
            }
          />
          <FAQSingleQuestion
            title={t('What is an Interest-Bearing Balance?')}
            text={
              <Trans>
                An interest-bearing balance is the part of your debt or loan where you are charged extra money (interest) for borrowing it.
                For example, if you don&apos;t pay your full credit card bill, the unpaid amount starts to earn interest, so it becomes interest-bearing.

                For loans, most of the unpaid amount is usually interest-bearing.
              </Trans>
            }
          />
        </Grid>
      </Grid>
      <MarketingConsentBox />
      <BlancoApiFormContextProvider>
        <BlancoDataFormContextProvider defaultData={defaultData}>
          <BlancoNorwayFormStepperDialog />
        </BlancoDataFormContextProvider>
      </BlancoApiFormContextProvider>
    </Container>
  );
};


export default GrFirstCredits;
