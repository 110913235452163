import { FC } from 'react';

import { LoanType } from 'src/api/zrm';
import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';

import MortgageNorwayLoansSection from './MortgageNorwayLoansSection';

interface BlancoNorwayLoansStepProps {
}

const BlancoNorwayLoansStep: FC<BlancoNorwayLoansStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <MortgageNorwayLoansSection
          formKey='carLoan'
          index={1}
          title='Do you have a car loan?'
          key='carLoan'
          loanType={LoanType.CAR_LOAN}
        />,
        <MortgageNorwayLoansSection
          formKey='mortgageLoan'
          index={2}
          title='Do you have a mortgage loan?'
          key='mortgageLoan'
          loanType={LoanType.MORTGAGE}
        />,
        <MortgageNorwayLoansSection
          formKey='otherLoan'
          index={3}
          title='Do you have any other loan?'
          key='otherLoan'
          loanType={LoanType.OTHER}
        />,
      ]}
    />
  );
};


export default BlancoNorwayLoansStep;
