import { FC } from 'react';

import { Box } from '@mui/material';

const CondominiumIcon: FC = () => {
  return (
    <Box>
      <img
        src={`${process.env.PUBLIC_URL || ''}/static/condominium.svg`}
        height="50"
        width="auto"
        alt="HOUSE"
      />
    </Box>
  );
};


export default CondominiumIcon;
