import { FC, ReactNode, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { LoanType } from 'src/api/zrm';
import DevTools from 'src/components/DevTools/DevTools';
import LoanInterestRateField from 'src/components/fields/Loans/LoanInterestRateField';
import LoanMonthlyPaymentField from 'src/components/fields/Loans/LoanMonthlyPaymentField';
import LoanQuestionField from 'src/components/fields/Loans/LoanQuestionField';
import LoanResponsibilityField from 'src/components/fields/Loans/LoanResponsibilityField';
import LoanTotalAmountField from 'src/components/fields/Loans/LoanTotalAmountField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import MortgageNorwayForm from 'src/types/MortgageNorway/form/MortgageNorwayForm';
import MortgageNorwayLoansForm from 'src/types/MortgageNorway/form/MortgageNorwayLoansForm';

interface MortgageNorwayLoansSectionProps {
  formKey: keyof MortgageNorwayLoansForm;
  title: string;
  index: number;
  loanType: LoanType;
}

const MortgageNorwayLoansSection: FC<MortgageNorwayLoansSectionProps> = (props) => {
  const { formKey, title, index, loanType } = props;
  const { formData, updateFormSection } = useMortgageDataFormContext();
  const { schemaFields } = useYupSchemaContext();


  const validationSchema = Yup.object().shape({
    hasLoan: schemaFields.securedLoans.hasLoan,
    totalAmount: schemaFields.securedLoans.totalAmount,
    rate: schemaFields.securedLoans.rate,
    monthlyPayment: schemaFields.securedLoans.monthlyPayment,
    multipleBorrowers: schemaFields.securedLoans.multipleBorrowers,
  });

  const initialValues = useMemo(() => {
    const data = formData as MortgageNorwayForm;

    return {
      ...data.loans[formKey],
    };
  }, [formData, formKey]);


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => {
            const temp = { ...values, loanType };
            updateFormSection(`loans.${formKey}`, temp);
          }}
          heading={`Debt Information (${index}/3)`}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <LoanQuestionField
                name='hasLoan'
                title={title}
              />
            </Grid>
            {values.hasLoan && (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <LoanTotalAmountField name='totalAmount' />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <LoanInterestRateField name='rate' />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <LoanMonthlyPaymentField name='monthlyPayment' />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <LoanResponsibilityField name='multipleBorrowers' />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible={values.hasLoan} />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};

export default MortgageNorwayLoansSection;
