import { FC, useEffect } from 'react';

import StepperDialog from 'src/components/dialogs/StepperDialog/StepperDialog';
import FormStepper from 'src/components/Stepper/FormStepper';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import BlancoSwedenForm from 'src/types/BlancoSweden/form/BlancoSwedenForm';
import SingleStep from 'src/types/Stepper/SingleStep';
import SkipStep, { SkipStepMode } from 'src/types/Stepper/SkipStep';
import isEmploymentNameRequired from 'src/utils/employmentType/isEmployerNameRequired';

import BlancoSwedenCoApplicantStep from './sections/coApplicant/BlancoSwedenCoApplicantStep';
import BlancoSwedenFamilyAndHousingStep from './sections/familyAndHousing/BlancoSwedenFamilyAndHousingStep';
import BlancoSwedenIncomeStep from './sections/income/BlancoSwedenIncomeStep';
import BlancoSharedContactStep from '../BlancoShared/sections/contact/BlancoSharedContactStep';

const steps: SingleStep[] = [
  {
    label: 'Family',
    component: <BlancoSwedenFamilyAndHousingStep />,
    subStepsCount: 4,
  },
  {
    label: 'Income',
    component: <BlancoSwedenIncomeStep />,
    subStepsCount: 3,
  },
  {
    label: 'Contact',
    component: <BlancoSharedContactStep />,
    subStepsCount: 3,
  },
  {
    label: 'Co-Applicant',
    component: <BlancoSwedenCoApplicantStep />,
    subStepsCount: 8,
  },
];

const skippable: SkipStep[] = [
  {
    mode: SkipStepMode.SUB_STEP,
    stepNumber: 1,
    subStepNumber: 2,
    skipOn: (values: BlancoSwedenForm) => !isEmploymentNameRequired(values.income.employmentType),
  },
  {
    mode: SkipStepMode.SUB_STEP,
    stepNumber: 3,
    subStepNumber: 6,
    skipOn: (values: BlancoSwedenForm) => !isEmploymentNameRequired(values.coApplicant.income.employmentType),
  },
  {
    mode: SkipStepMode.STEP,
    stepNumber: 3,
    subStepNumber: 0,
    skipOn: (values: BlancoSwedenForm) => !values.coApplicant.hasCoApplicant,
  },
];

interface BlancoSwedenFormStepperDialogProps { }

const BlancoSwedenFormStepperDialog: FC<BlancoSwedenFormStepperDialogProps> = (props) => {
  const { } = props;

  const { setSteps, setSkippableSteps } = useFormStepperDialogContext();

  useEffect(() => {
    setSteps(steps);
    setSkippableSteps(skippable);
  }, []);

  return (
    <StepperDialog>
      <FormStepper steps={steps} />
    </StepperDialog>
  );
};


export default BlancoSwedenFormStepperDialog;
