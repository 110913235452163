import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useFormSelectorContext } from 'src/contexts/FormSelectorContext';
import BlancoNorwayConstants from 'src/defaults/BlancoNorway/constants';
import BlancoSwedenConstants from 'src/defaults/BlancoSweden/constants';
import MortgageNorwayConstans from 'src/defaults/MortgageNorway/constants';
import OnlineFormType from 'src/types/OnlineFormType';
import { isSweden } from 'src/utils/country/isCountry';

import FieldWithSlider from './base/FieldWithSlider';

interface LoanAmountFieldProps {
  name: string;
  title?: string;
}

const LoanAmountField: FC<LoanAmountFieldProps> = (props) => {
  const { name, title = 'Loan Amount' } = props;

  const { t } = useTranslation();
  const isCountrySweden = isSweden();
  const { onlineFormType } = useFormSelectorContext();

  const consts = useMemo(() => {

    if (onlineFormType === OnlineFormType.MortgageNorway) {
      return MortgageNorwayConstans;
    } else {
      return isCountrySweden ? BlancoSwedenConstants : BlancoNorwayConstants;
    }

  }, [isCountrySweden]);

  return (
    <FieldWithSlider
      name={name}
      title={t(title)}
      min={consts.minLoanAmount}
      max={consts.maxLoanAmount}
      resetOnFirstFocus
    />

  );
};


export default LoanAmountField;
