import { FC } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import EvConsentInfoBox from 'src/components/eiendomsverdi/EvConsentCheckbox';
import { useLoginContext } from 'src/contexts/auth/LoginContext';

interface EvFirstStartPageProps { }

const EvFirstStartPage: FC<EvFirstStartPageProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { loginBankIdNo, loginError } = useLoginContext();

  const login = () => {
    loginBankIdNo();
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center' }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
          {t('What is the value of your home?')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <EvConsentInfoBox />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <FlexCenterBox>
          <Button
            variant='contained'
            onClick={login}
          >
            {t('Retrieve My Property Information')}
          </Button>
        </FlexCenterBox>
      </Grid>
      {loginError && (
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <FlexCenterBox>
            <Typography sx={{ fontSize: '0.75rem', color: 'error.main' }}>
              {t('There was an unexpected error logging in. Please try again.')}
            </Typography>
          </FlexCenterBox>
        </Grid>
      )}
    </Grid>
  );
};


export default EvFirstStartPage;
