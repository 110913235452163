import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';

import MortgageSharedChildrenSection from './MortgageSharedChildrenSection';
import MortgageSharedLivingCostSection from './MortgageSharedLivingCostSection';
import MortgageSharedLivingSituationSection from './MortgageSharedLivingSituationSection';
import MortgageSharedMaritalStatusSection from './MortgageSharedMaritalStatusSection';

interface MortgageSharedFamilyAndHousingStepProps {
}

const MortgageSharedFamilyAndHousingStep: FC<MortgageSharedFamilyAndHousingStepProps> = (props) => {
  const { } = props;

  return (
    <FormStepperSubStepsContainer
      stepsInOrder={
        [
          <MortgageSharedMaritalStatusSection key='MortgageSharedMaritalStatusSection' />,
          <MortgageSharedChildrenSection key='MortgageSharedChildrenSection' />,
          <MortgageSharedLivingSituationSection key='MortgageSharedLivingSituationSection' />,
          <MortgageSharedLivingCostSection key='MortgageSharedLivingCostSection' />,
        ]}
    />
  );
};


export default MortgageSharedFamilyAndHousingStep;
