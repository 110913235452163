import { FC } from 'react';

import { Box } from '@mui/material';

const VacationHouseIcon: FC = () => {
  return (
    <Box>
      <img
        src={`${process.env.PUBLIC_URL || ''}/static/vacation_house.svg`}
        height="50"
        width="auto"
        alt="HOUSE"
      />
    </Box>
  );
};


export default VacationHouseIcon;
