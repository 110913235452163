import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import MortgageEmployerNameSection from 'src/forms/MortgageShared/sections/income/MortgageEmployerNameSection';
import MortgageEmploymentTypeSection from 'src/forms/MortgageShared/sections/income/MortgageEmploymentTypeSection';

import MortgageNorwayIncomeAmountSection from './MortgageNorwayIncomeAmountSection';
import MortgageNorwayRentIncomeAmountSection from './MortgageNorwayRentIncomeAmountSection';


interface MortgageNorwayIncomeStepProps {
}

const MortgageNorwayIncomeStep: FC<MortgageNorwayIncomeStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <MortgageEmploymentTypeSection key='MortgageEmploymentTypeSection' />,
        <MortgageNorwayIncomeAmountSection key='MortgageNorwayIncomeAmountSection' />,
        <MortgageEmployerNameSection key='MortgageEmployerNameSection' />,
        <MortgageNorwayRentIncomeAmountSection key='MortgageNorwayRentIncomeAmountSection' />,
      ]}
    />
  );
};


export default MortgageNorwayIncomeStep;
