import { FC, useMemo } from 'react';

import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ApplicantNO, Country, PartialCondominium, PartialHouse, PartialVacationHome } from 'src/api/zrm';
import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import MarketingConsentBox from 'src/components/Consents/MarketingConsentBox';
import EvPropertyMobileList from 'src/components/eiendomsverdi/EvPropertyMobileList';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';
import { BlancoApiFormContextProvider } from 'src/contexts/blanco/BlancoApiFormContext';
import { BlancoDataFormContextProvider } from 'src/contexts/blanco/BlancoDataFormContext';
import BlancoNorwayFormDefaultData from 'src/defaults/BlancoNorway/BlancoNorwayFormDefaultData';
import BlancoNorwayFormStepperDialog from 'src/forms/BlancoNorway/BlancoNorwayFormStepperDialog';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import formatCurrency from 'src/utils/format/formatCurrency';

interface EvFirstPropertiesProps {
  properties: (PartialHouse | PartialCondominium | PartialVacationHome)[];
}

const EvFirstProperties: FC<EvFirstPropertiesProps> = (props) => {
  const { properties } = props;

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { customerData } = useCustomerDataContext();

  const totalAmount = useMemo(() => properties?.reduce((prev, curr) => prev + curr.ev_partial?.net_value_estimate, 0) || 0, [properties]);

  const defaultData: BlancoNorwayForm = {
    ...BlancoNorwayFormDefaultData,
    startPage: {
      ...BlancoNorwayFormDefaultData.startPage,
      loanAmount: +totalAmount.toFixed(0),
      refinanceAmount: +totalAmount.toFixed(0),
      refinance: true,
    },
    pni: {
      pni: (customerData as ApplicantNO)?.pni,
    },
    extraData: {
      isGrFirst: true,
    },
  };


  return (
    <>
      <Container
        maxWidth='xl'
      >
        {!!properties.length && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ '& .MuiTypography-root': { fontWeight: 'bold' }, mt: 5 }}
            >
              <Typography sx={{ fontSize: '2rem', mb: 1 }}>
                {t('My Property Value')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '1.25rem' }}>
                  {t('Total Value')}
                </Typography>
                <Typography sx={{ fontSize: '1.25rem' }}>
                  {formatCurrency(totalAmount, Country.NO, 0)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>
                  {t('Number of Properties')}
                </Typography>
                <Typography>
                  {properties?.length || 0}
                  {t(' st')}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ mt: 2 }}
            >
              <FlexCenterBox>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  //onClick={(() => openDialog({}))}
                  //TODO: Add onClick to Mortgage form
                  href='https://www.zensum.no/bolig'
                >
                  {t('Compare your mortgage rate')}
                </Button>
              </FlexCenterBox>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <EvPropertyMobileList properties={properties} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1 }}>
                  {t('Are you paying too much for your mortgage?')}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  //onClick={(() => openDialog({}))}
                  //TODO: Add onClick to Mortgage form
                  href='https://www.zensum.no/bolig'
                >
                  {t('Challenge your rate – compare')}
                </Button>
              </FlexCenterBox>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1, align: 'center' }}>
                  {t('Convert expensive unsecured loans into a mortgage?')}
                </Typography>
                <Button
                  variant='outlined'
                  color='primary'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  href={'https://www.zensum.no/verifisering'}
                >
                  {t('See if it is possible for you')}
                </Button>
              </FlexCenterBox>
            </Grid>
          </Grid>
        )}
        {!properties.length && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sx={{ mt: 15, mb: 5 }}
              textAlign={'center'}
            >
              <Typography>{t('You don\'t have any properties.')}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox
                sx={{ flexDirection: 'column' }}
                textAlign="center"
              >
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', mb: 1 }}>
                  {t('Convert expensive unsecured loans into a mortgage?')}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  href={'https://www.zensum.no/verifisering'}
                >
                  {t('See if it is possible for you')}
                </Button>
              </FlexCenterBox>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{ mb: 2, mt: 5 }}
        >
          <Typography>
            <Trans>
              By proceeding to &quot;See if it is possible for you&quot; I consent to Zensum&apos;s
              <Link href="https://www.zensum.no/brukervilkar" >
                {' Terms of Service '}
              </Link>
              and to my debt data being retrieved and stored for 12 months in accordance with applicable law. The data is processed in line with Zensum&apos;s
              <Link href="https://www.zensum.no/personvern">
                {' Privacy Policy '}
              </Link>
              and analyzed for better loan opportunities. The consent is deleted on
              <Link href="http://gjeldsregisteret.com/">
                {' Gjeldsregisteret.'}
              </Link>
            </Trans>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 2, mt: 2 }}
        >
          <Typography>
            <Trans>
              The properties information is provided by
              {' '}
              <Link
                href='https://home.eiendomsverdi.no/'
                target='_blank'
              >
                Eiendomsverdi.no
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
        <MarketingConsentBox />
        <BlancoApiFormContextProvider>
          <BlancoDataFormContextProvider defaultData={defaultData}>
            <BlancoNorwayFormStepperDialog />
          </BlancoDataFormContextProvider>
        </BlancoApiFormContextProvider>
      </Container>
    </>
  );
};


export default EvFirstProperties;
