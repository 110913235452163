const deepUpdate = (obj: any, path: string, value: any,) => {
  const p = path.split('.');

  let i;
  for (i = 0; i < p.length - 1; i++) { obj = obj[p[i]]; }

  obj[p[i]] = value;
};

export default deepUpdate;
