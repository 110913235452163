import { FC, useMemo } from 'react';

import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { t } from 'i18next';

import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface YearFieldProps {
  name: string;
  maxYear?: number;
}


const YearField: FC<YearFieldProps> = (props) => {
  const { name, maxYear } = props;

  const options = (maxYear > 0 ?
    new Array(maxYear - new Date().getFullYear()).fill(0).map((_, index) => (new Date().getFullYear() + index).toString())
    :
    new Array(30).fill(0).map((_, index) => (new Date().getFullYear() - index).toString())
  );

  const { values, handleChange, errors, touched, handleBlur } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);

  const isTouched = getValueByPath<boolean>(touched, name);

  const value: string = useMemo(() => getValueByPath<string>(values, name) ?? '', [values, name]);

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          disableClearable
          options={options}
          value={value}
          inputValue={value}
          onInputChange={(e, newValue: string) => {
            handleChange({ ...e, target: { name, value: newValue } });
          }}
          getOptionLabel={(option) => '' + option}
          onChange={(e, newValue: string) => {
            handleChange({ ...e, target: { name, value: newValue } });
          }}
          selectOnFocus
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Year')}
              error={!!error && isTouched}
              type='number'
              name={name}
              onBlur={handleBlur}
              fullWidth
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler name={name} />
    </Grid>
  );
};


export default YearField;