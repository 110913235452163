import { FC } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FlexCenterBox from '../boxes/FlexCenterBox';

const CustomerDataLoading: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <FlexCenterBox sx={{ mb: 2 }}>
        <Typography sx={{ fontStyle: 'italic' }}>
          {t('Please wait until your data is loaded...')}
        </Typography>
      </FlexCenterBox>
      <FlexCenterBox>
        <CircularProgress
          color="success"
          size={60}
        />
      </FlexCenterBox>
    </>
  );
};

export default CustomerDataLoading;
