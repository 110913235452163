import { useEffect, useMemo, useState } from 'react';

import { v4 } from 'uuid';

import { useApi } from 'src/contexts/APIContext';
import { useAuthContext } from 'src/contexts/auth/AuthContext';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';


const useFetchMe = () => {
  const { api } = useApi();
  const { authState } = useAuthContext();
  const { customerData, setCustomerData } = useCustomerDataContext();

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const getMe = useMemo(() => async () => {
    const requestId = v4();
    setLoading(true);

    try {
      const resp = await api.myPages.getApplicantMyPagesApplicantMeGet(
        { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      setCustomerData(resp.data);
    } catch (error) { }

    setLoading(false);
    setDataLoaded(true);
  }, [api]);

  useEffect(() => {
    if (authState?.authenticated) {
      getMe();
    }
  }, [authState?.authenticated]);


  const hasEmailAndPhone = useMemo(() => customerData?.email && customerData?.phone_number, [customerData]);

  return { loading, hasEmailAndPhone, dataLoaded };
};

export default useFetchMe;
