import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import CoApplicantRelationshipField from 'src/components/fields/Family/CoApplicantRelationshipField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useMortgageDataFormContext } from 'src/contexts/mortgage/MortgageDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface MortgageSharedCoApplicantRelationshipSectionProps {
  
}

const MortgageSharedCoApplicantRelationshipSectionSection: FC<MortgageSharedCoApplicantRelationshipSectionProps> = () => {
 
  const { formData, updateFormField } = useMortgageDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    coApplicantRelationship: schemaFields.family.coApplicantRelationship,
  });

  const initialValues = {
    coApplicantRelationship:  formData.coApplicant.coApplicantRelationship,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormField('coApplicant.coApplicantRelationship', values.coApplicantRelationship)}
          heading={'What is your relationship to co-applicant?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <CoApplicantRelationshipField
                name='coApplicantRelationship'
               
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};


export default MortgageSharedCoApplicantRelationshipSectionSection;
