import { FC, useMemo } from 'react';

import { Box, BoxProps } from '@mui/material';
import { Trans } from 'react-i18next';

import CurrencyFieldSuffix from 'src/types/Fields/CurrencyFieldSuffix';

const ContainerBox: FC<BoxProps> = (props) => {
  const { children, ...other } = props;

  return (
    <Box
      component="span"
      sx={{ width: 'fit-content', whiteSpace: 'nowrap', pointerEvents: 'none' }}
      {...other}
    >
      {children}
    </Box>
  );
};

const AdditionalInfoBox: FC<BoxProps> = (props) => {
  const { children, ...other } = props;

  return (
    <Box
      component="span"
      sx={{ color: 'gray' }}
      {...other}
    >
      {children}
    </Box>
  );
};


interface CurrencyFieldSuffixSlotProps {
  suffix: CurrencyFieldSuffix;
  coApplicant?: boolean;
}

const CurrencyFieldSuffixSlot: FC<CurrencyFieldSuffixSlotProps> = (props) => {
  const { suffix, coApplicant = false } = props;

  const shareString = useMemo(() => coApplicant ? 'co-applicant\'s share' : 'your share', [coApplicant]);

  if (suffix === CurrencyFieldSuffix.KR_PER_YEAR_BEFORE_TAX) {
    return (
      <ContainerBox>
        <Trans>
          kr / year
          {' '}
          <AdditionalInfoBox>
            (before tax)
          </AdditionalInfoBox>
        </Trans>
      </ContainerBox>
    );
  }

  if (suffix === CurrencyFieldSuffix.KR_PER_MONTH) {
    return (
      <ContainerBox>
        <Trans>
          kr / month
        </Trans>
      </ContainerBox>
    );
  }

  if (suffix === CurrencyFieldSuffix.KR_PER_MONTH_YOUR_SHARE) {
    return (
      <ContainerBox>
        <Trans>
          kr / month
          {' '}
          <AdditionalInfoBox>
            ({shareString})
          </AdditionalInfoBox>
        </Trans>
      </ContainerBox>
    );
  }

  if (suffix === CurrencyFieldSuffix.KR_PER_MONTH_BEFORE_TAX) {
    return (
      <ContainerBox>
        <Trans>
          kr / month
          {' '}
          <AdditionalInfoBox>
            (before tax)
          </AdditionalInfoBox>
        </Trans>
      </ContainerBox>
    );
  }

  return (
    <>kr</>
  );
};

export default CurrencyFieldSuffixSlot;
