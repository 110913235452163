
import { FC, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-redeclare
import history from 'history/browser';

import FormSelector from './components/FormEntry/FormSelector';
import { MarketingConsentContextProvider } from './contexts/consents/MarketingConsentContext';
import { FormSelectorContextProvider } from './contexts/FormSelectorContext';
import { FormStepperDialogContextProvider } from './contexts/FormStepperDialogContext';
import { useSettingsContext } from './contexts/SettingsContext';
import EvFirst from './eiendomsverdi/EvFirst';
import GrFirst from './gr/GrFirst/GrFirst';
import OnlineFormType from './types/OnlineFormType';

interface AppProps {
  onlineFormType: OnlineFormType;
}

const App: FC<AppProps> = (props) => {
  const { onlineFormType } = props;

  const { grFirst, evFirst } = useSettingsContext();

  // Fix edge case where user navigates to page via URL with steps in pathname
  useEffect(() => {
    if (/^\#step\d*-\d+/.test(history.location.hash) || /^\#steg\d*-\d+/.test(history.location.hash)) {
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    }
  }, []);

  return (
    <>
      {grFirst && (
        <MarketingConsentContextProvider>
          <FormSelectorContextProvider onlineFormType={onlineFormType}>
            <FormStepperDialogContextProvider>
              <GrFirst />
            </FormStepperDialogContextProvider>
          </FormSelectorContextProvider>
        </MarketingConsentContextProvider>
      )}
      {evFirst && (
        <MarketingConsentContextProvider>
          <FormSelectorContextProvider onlineFormType={onlineFormType}>
            <FormStepperDialogContextProvider>
              <EvFirst />
            </FormStepperDialogContextProvider>
          </FormSelectorContextProvider>
        </MarketingConsentContextProvider>
      )}
      {!grFirst && !evFirst && (
        <FormSelectorContextProvider onlineFormType={onlineFormType}>
          <FormStepperDialogContextProvider>
            <FormSelector />
          </FormStepperDialogContextProvider>
        </FormSelectorContextProvider>
      )}
    </>
  );
};

export default App;
