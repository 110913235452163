import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { Api } from 'src/api/zrm';

import { useAuthContext } from './auth/AuthContext';

type BearerToken = string;
export type ZRMApi = Api<BearerToken>;

interface APIs {
  api: ZRMApi,
  analyticsApi: ZRMApi,
  setFillingIdHeader: (fillingId: string) => void;
}
interface Props {
  children?: ReactNode
}

const APIContext = createContext<APIs>(null);

export const APIProvider = ({ children }: Props) => {

  const { authState } = useAuthContext();

  const [fillingId, setFillingId] = useState(null);


  const headers = useMemo(() => {
    
    const tmpHeaders: Record<string, string> = {};

    if (authState?.authenticated) {
      tmpHeaders.Authorization = `Bearer ${authState?.token}`;
    }

    if (fillingId) {
      tmpHeaders['X-Filling-Id'] = fillingId;
    }


    return tmpHeaders;
  }, [authState?.token, authState?.authenticated, fillingId]);

  const value = useMemo((): APIs => {

    const securityWorker = () =>  {
      return { headers: headers  };
    };

    const baseApiParams = { secure: true, headers: {} };
    // as of 2024, for cookies to work in the browser, the top domain must be the same as the one that set the cookie
    // so we need to use the correct domain for the correct environment
    let baseUrl = process.env.REACT_APP_ONLINE_SE_API_URL;
    let analyticsBaseUrl = process.env.REACT_APP_ANALYTICS_SE_API_URL;

    if (window.location.hostname.endsWith('zensum.no')) {
      baseUrl = process.env.REACT_APP_ONLINE_NO_API_URL;
      analyticsBaseUrl = process.env.REACT_APP_ANALYTICS_NO_API_URL;
    }

    return {
      api: new Api({
        baseUrl: baseUrl,
        securityWorker,
        baseApiParams: { ...baseApiParams, credentials: 'include' },
      }),
      analyticsApi: new Api({
        baseUrl: analyticsBaseUrl,
        baseApiParams: { ...baseApiParams, credentials: 'include' },
      }),
      setFillingIdHeader(newFillingId: string) {
        setFillingId(newFillingId);
      },
    };
  }, [authState?.token, authState?.authenticated, fillingId]);

  return (
    <APIContext.Provider value={value}>
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;

export const useApi = () => useContext(APIContext);
