import { FC, useMemo } from 'react';

import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

import { OnlineFormEventType, OnlineFormEventSubType } from 'src/api/zrm';
import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';

const AnalyticsWrappedToggleButtonGroup: FC<ToggleButtonGroupProps> = (props) => {

  const { children, onChange, id, ...restprops } = props;
  const { analyticsAddEvent } = useAnalyticsContext();

  const changeAndSendEvent = useMemo(() => (e: any, v: any) => {
    analyticsAddEvent(OnlineFormEventType.CLICK, OnlineFormEventSubType.BUTTON, { name: id, value: v });

    return onChange(e, v);
  }, [onChange, id]);

  return (
    <ToggleButtonGroup
      {...restprops}
      id={id}
      onChange={changeAndSendEvent}
    >
      {children}
    </ToggleButtonGroup >
  );
};

export default AnalyticsWrappedToggleButtonGroup;
