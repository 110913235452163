import { Constants } from '../Constants';

const BlancoNorwayConstants: Constants = {
  maxLoanAmount: 800_000,
  minLoanAmount: 20_000,
  maxLivingCost: 100_000,
  defaultAmount: 150_000,
  defaultRepaymentTime: 8,
  maxMonthlyIncome: 1_000_000,
  minMonthlyIncome: 10_000,
  maxYearlyIncome: 12_000_000,
  minYearlyIncome: 200_000,
  minYearlyRentIncome: 1,
  maxYearlyRentIncome: 9_999_999,
  minTotalAmount: 1,
  maxTotalAmount: 99_999_999,
  maxMonthlyPayment: 250_000,
  maxRate: 50, //max rate is 50%
};

export default BlancoNorwayConstants;