import { FC } from 'react';

import { Box, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Trans } from 'react-i18next';

import Norway from 'src/components/countrySpecific/Norway';
import Sweden from 'src/components/countrySpecific/Sweden';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

import FieldChangeHandler from '../FieldChangeHandler/FieldChangeHandler';

interface ConsentCheckboxProps {
  name: string;
}

const ConsentCheckbox: FC<ConsentCheckboxProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const value = getValueByPath<boolean>(values, name);
  const isTouched = !!getValueByPath<boolean>(touched, name);

  return (
    <Box>
      <FormControlLabel
        checked={value}
        color="primary"
        control={<Checkbox size='small' />}
        onChange={() => setFieldValue(name, !value)}
        label={<Typography fontSize={'small'}>
          <Sweden>
            <Trans>
              I want to subscribe to
              {' '}
              <Link
                href="https://www.zensum.se/blogg"
                target="_blank"
                rel="noreferrer"
              >
                Zensum News
              </Link>
              {' '}
              to receive newsletters and offers via SMS and email
            </Trans>
          </Sweden>
          <Norway>
            <Trans>
              I want to subscribe to
              {' '}
              <Link
                href="https://www.zensum.no/brukervilkar#news"
                target="_blank"
                rel="noreferrer"
              >
                Zensum News
              </Link>
              {' '}
              to receive newsletters and offers via SMS and email
            </Trans>
          </Norway>
        </Typography>}
        sx={{ width: '100%' }}
      />
      {error && isTouched && (
        <Typography
          variant='caption'
          color='error'
          sx={{ px: 4 }}
        >
          {error}
        </Typography>
      )}
      <FieldChangeHandler name={name} />
    </Box>
  );
};


export default ConsentCheckbox;
