import { FC, useEffect, useMemo, useState } from 'react';

import { Container } from '@mui/material';
import { v4 } from 'uuid';

import { ApplicantNO, BodyDebtRegisterWithConsentMyPagesDebtRegisterWithConsentPost } from 'src/api/zrm';
import BankIdUserData from 'src/components/auth/BankIdUserData';
import CustomerDataLoading from 'src/components/loading/CustomerDataLoading';
import { useApi } from 'src/contexts/APIContext';
import { useAuthContext } from 'src/contexts/auth/AuthContext';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import useFetchMe from 'src/hooks/fetch/useFetchMe';
import ContactData from 'src/types/auth/ContactData';

import GrFirstAppCreatedPage from './GrFirstAppCreatedPage';
import GrFirstCredits from './GrFirstCredits';
import GrFirstStartPage from './GrFirstStartPage';

interface GrFirstProps { }

const GrFirst: FC<GrFirstProps> = (props) => {
  const { } = props;

  const { checkBankIdNoAuth, authState, authFinished } = useAuthContext();
  const { appIsCreated } = useFormStepperDialogContext();
  const { setCustomerData } = useCustomerDataContext();
  const { api } = useApi();
  const { loading: fetchMeLoading, hasEmailAndPhone, dataLoaded: meDataLoaded } = useFetchMe();

  const [grDataLoading, setGrDataLoading] = useState(false);
  const [grDataLoaded, setGrDataLoaded] = useState(false);

  const fetchGRData = useMemo(() => async (data?: ContactData) => {
    const requestId = v4();
    setGrDataLoading(true);

    let payload: BodyDebtRegisterWithConsentMyPagesDebtRegisterWithConsentPost = { email: '', phone_number: '', marketing_consent: false };

    if (!hasEmailAndPhone) {
      payload = { email: data?.email, phone_number: data?.phone, marketing_consent: data?.marketingConsent };
    }

    try {
      const resp = await api.myPages.debtRegisterWithConsentMyPagesDebtRegisterWithConsentPost(
        payload,
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data as ApplicantNO);
    } catch (e) { }

    setGrDataLoading(false);
    setGrDataLoaded(true);
  }, [api, hasEmailAndPhone]);

  useEffect(() => {
    if (authState?.authenticated && hasEmailAndPhone) {
      fetchGRData();
    }
  }, [authState?.authenticated, hasEmailAndPhone]);

  useEffect(() => {
    checkBankIdNoAuth();
  }, []);

  const loading = fetchMeLoading || grDataLoading;

  if (!authFinished) {
    return <></>;
  }

  return (
    <>
      {authState?.authenticated && (
        <>
          {appIsCreated && (
            <GrFirstAppCreatedPage />
          )}
          {!appIsCreated && (
            <>
              {loading && (
                <CustomerDataLoading />
              )}
              {!loading && grDataLoaded && hasEmailAndPhone && (
                <GrFirstCredits />
              )}
              {!loading && meDataLoaded && !hasEmailAndPhone && (
                <BankIdUserData
                  callback={(data: ContactData) => {
                    fetchGRData(data);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      {!authState?.authenticated && (
        <Container
          maxWidth='sm'
          disableGutters
        >
          <GrFirstStartPage />
        </Container>
      )}
    </>
  );
};


export default GrFirst;
