import { FC, ReactNode } from 'react';

import { Button, Container, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import EmailField from 'src/components/fields/Contact/EmailField';
import PhoneField from 'src/components/fields/Contact/PhoneField';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import ContactData from 'src/types/auth/ContactData';

import FlexCenterBox from '../boxes/FlexCenterBox';
import MarketingConsentAuthPageCheckbox from '../Consents/MarketingConsentAuthPageCheckbox';

interface BankIdUserDataProps {
  callback: (data: ContactData) => void;
}

const BankIdUserData: FC<BankIdUserDataProps> = (props) => {
  const { callback } = props;

  const { t } = useTranslation();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    phone: schemaFields.contact.phone,
    email: schemaFields.contact.email,
  });

  const initialValues = {
    phone: '',
    email: '',
    marketingConsent: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({ values, setFieldValue, setFieldTouched, validateForm, isValid }): ReactNode => {

        return (
          <Container maxWidth='sm'>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Typography>{t('Enter your contact details')}:</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <PhoneField
                  name='phone'
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <EmailField
                  name='email'
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <MarketingConsentAuthPageCheckbox
                  checked={values.marketingConsent}
                  setChecked={(v) => setFieldValue('marketingConsent', v)}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FlexCenterBox>
                  <Button
                    variant='contained'
                    onClick={async () => {
                      setFieldTouched('phone', true);
                      setFieldTouched('email', true);
                      await validateForm();

                      if (isValid) {
                        callback(values);
                      }
                    }}
                  >
                    {t('Retrieve My Credits')}
                  </Button>
                </FlexCenterBox>
              </Grid>
            </Grid>
          </Container>
        );
      }}
    </Formik>
  );
};


export default BankIdUserData;
