import { FC, useEffect, useState } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { LoanPurposeNO, LoanPurposeSE } from 'src/api/zrm';
import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import loanPurposesByCountry from 'src/defaults/loanPurpose/loanPurposesByCountry';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface GrNewLoanPurposeFieldProps {
  name: string;
}

// TODO: Some of this code is reused with `RefinanceFormField.tsx` file. Maybe it can be extracted to a common file.
const GrNewLoanPurposeField: FC<GrNewLoanPurposeFieldProps> = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const { country } = useSettingsContext();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();

  const [otherPurposeSelected, setOtherPurposeSelected] = useState(false);

  const newLoanPurpose = getValueByPath<LoanPurposeSE | LoanPurposeNO>(values, name);
  const newLoanPurposeError = getValueByPath<string>(errors, name);
  const newLoanPurposeIsTouched = getValueByPath<boolean>(touched, name);
  const loanPurposes = loanPurposesByCountry[country];

  const toggleOtherPurposeSelected = () => {
    setOtherPurposeSelected(p => !p);
    setFieldValue(name, null);
    setFieldTouched(name, false, false);
  };

  useEffect(() => {
    if (loanPurposes?.otherLoanPurposes?.includes(newLoanPurpose)) { setOtherPurposeSelected(true); }
  }, [newLoanPurpose]);

  return (

    <StyledToggleButtonGroup
      id={name}
      value={newLoanPurpose}
      exclusive
      onChange={(e, v) => {
        if (v !== null) {
          setFieldValue(name, v, true);
          // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
          setFieldTouched(name, true, false);
        }
      }}
      className={(newLoanPurposeError && newLoanPurposeIsTouched) ? 'error' : ''}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('What is the main purpose for the loan?')}
          </Typography>
        </Grid>
        {loanPurposes.mainLoanPurposes.map((purpose) => (
          <Grid
            item
            xs={6}
            md={4}
            key={purpose}
          >
            <StyledToggleButton
              value={purpose}
              className='no-uppercase'
            >
              {t(`loanPurpose-${purpose}`)}
            </StyledToggleButton>
          </Grid>
        ))}
        <Grid
          item
          xs={6}
          md={4}
        >
          <Button
            variant="outlined"
            onClick={toggleOtherPurposeSelected}
            sx={{
              width: '100%',
              height: '100%',
              color: otherPurposeSelected ? 'primary.main' : 'inherit',
              borderColor: otherPurposeSelected ? 'primary.main' : 'rgba(0, 0, 0, 0.12)',
              textTransform: 'none',
            }}
          >
            {t('Other')}
          </Button>
        </Grid>
        {otherPurposeSelected && loanPurposes.otherLoanPurposes.map((purpose) => (
          <Grid
            item
            xs={6}
            md={4}
            key={purpose}
          >
            <StyledToggleButton
              value={purpose}
              className='no-uppercase'
            >
              {t(`loanPurpose-${purpose}`)}
            </StyledToggleButton>
          </Grid>
        ))}
        {newLoanPurposeError && newLoanPurposeIsTouched && (
          <Grid
            item
            xs={12}
          >
            <Typography
              variant='caption'
              color='error'
            >
              {newLoanPurposeError}
            </Typography>
          </Grid>
        )}
      </Grid>
      <FieldChangeHandler name={name} />
    </StyledToggleButtonGroup>

  );
};


export default GrNewLoanPurposeField;
