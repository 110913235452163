import { Country } from 'src/api/zrm';
import { Product } from 'src/api/zrm';

const defaultInterestRates:Record<Product, Record<Country, Record<'apr' | 'nominal', number>>>  = {
  [Product.Blanco]: { 
    [Country.SE]: {
      nominal: 0.0797,
      apr: 0.0827,
    },
    [Country.NO]: {
      nominal: 0.1090,
      apr: 0.1146,
    }, 
  },
  [Product.Mortgage]: {
    [Country.SE]: {
      nominal: 0.0797,
      apr: 0.0827,
    },
    [Country.NO]: {
      nominal: 0.0747,
      apr: 0.0777,
    },
  },
  [Product.CreditCard]: {
    [Country.SE]: {      
      nominal: 1,
      apr: 1,
    },
    [Country.NO]: {      
      nominal: 1,
      apr: 1,
    },
    
  },
};

export default defaultInterestRates;
