import { FC, useMemo } from 'react';

import { Grid, Typography } from '@mui/material';

import CreditCardIcon from 'src/components/eiendomsverdi/icons/CreditCardIcon';
import PrivateLoanIcon from 'src/components/eiendomsverdi/icons/PrivateLoanIcon';


interface GrCreditBoxMobileHeaderProps {
  title: string;
  creditType: string;
}

const GrCreditBoxMobileHeader: FC<GrCreditBoxMobileHeaderProps> = (props) => {
  const { title, creditType } = props;

  const icon = useMemo(() => {
    if (creditType === 'repaymentLoan') { return <PrivateLoanIcon />; }

    return <CreditCardIcon />;
  }, [creditType]);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', px: 3, py: 1, backgroundColor: '#e8f4ec', gap: 2, mb: 1 }}
      >
        {icon}
        <Typography sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Grid>
    </>
  );
};


export default GrCreditBoxMobileHeader;
