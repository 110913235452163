import { FC, useEffect } from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useMarketingConsentContext } from 'src/contexts/consents/MarketingConsentContext';

interface MarketingConsentAuthPageCheckboxProps {
  checked: boolean;
  setChecked: (value: boolean) => void;
}

const MarketingConsentAuthPageCheckbox: FC<MarketingConsentAuthPageCheckboxProps> = (props) => {
  const { checked, setChecked } = props;

  const { t } = useTranslation();
  const { setConsentWasShownWithUserDetailsForm } = useMarketingConsentContext();

  // Set that consent was shown so we won't display it later
  useEffect(() => {
    setConsentWasShownWithUserDetailsForm(true);
  }, []);

  return (
    <FormControlLabel
      checked={checked}
      color="primary"
      control={<Checkbox />}
      onChange={() => setChecked(!checked)}
      label={
        <Typography sx={{ textAlign: 'justify', fontSize: '0.9rem', lineHeight: '1.4' }}>
          {t('I consent to receiving tips and marketing from Zensum via email and SMS, which is not required to use the service and can easily be unsubscribed from in each message.')}
        </Typography>
      }
      sx={{ alignItems: 'flex-start', mr: 0, '& .MuiCheckbox-root': { pb: 0, pt: '1px' } }}
    />

  );
};

export default MarketingConsentAuthPageCheckbox;
