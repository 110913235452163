import { FC, useEffect } from 'react';

import StepperDialog from 'src/components/dialogs/StepperDialog/StepperDialog';
import FormStepper from 'src/components/Stepper/FormStepper';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import MortgageNorwayForm from 'src/types/MortgageNorway/form/MortgageNorwayForm';
import SingleStep from 'src/types/Stepper/SingleStep';
import SkipStep, { SkipStepMode } from 'src/types/Stepper/SkipStep';
import isEmploymentNameRequired from 'src/utils/employmentType/isEmployerNameRequired';

import MortgageNorwayCoApplicantStep from './sections/coApplicant/MortgageNorwayCoApplicantStep';
import MortgageNorwayIncomeStep from './sections/income/MortgageNorwayIncomeStep';
import MortgageNorwayLoansStep from './sections/loans/MortgageNorwayLoansStep';
import MortgageSharedContactStep from '../MortgageShared/sections/contact/MortgageSharedContactStep';
import MortgageSharedFamilyAndHousingStep from '../MortgageShared/sections/familyAndHousing/MortgageSharedFamilyAndHousingStep';

const steps: SingleStep[] = [
  {
    label: 'Family',
    component: <MortgageSharedFamilyAndHousingStep />,
    subStepsCount: 4,
  },
  {
    label: 'Income',
    component: <MortgageNorwayIncomeStep />,
    subStepsCount: 4,
  },
  {
    label: 'Secured Loans',
    component: <MortgageNorwayLoansStep />,
    subStepsCount: 3,
  },
  {
    label: 'Contact',
    component: <MortgageSharedContactStep />,
    subStepsCount: 3,
  },
  {
    label: 'Co-Applicant',
    component: <MortgageNorwayCoApplicantStep />,
    subStepsCount: 9,
  },
];

const skippable: SkipStep[] = [
  {
    mode: SkipStepMode.SUB_STEP,
    stepNumber: 1,
    subStepNumber: 2,
    skipOn: (values: MortgageNorwayForm) => !isEmploymentNameRequired(values.income.employmentType),
  },
  {
    mode: SkipStepMode.SUB_STEP,
    stepNumber: 3,
    subStepNumber: 7,
    skipOn: (values: MortgageNorwayForm) => !isEmploymentNameRequired(values.coApplicant.income.employmentType),
  },
];

interface MortgageNorwayFormStepperDialogProps { }

const MortgageNorwayFormStepperDialog: FC<MortgageNorwayFormStepperDialogProps> = (props) => {
  const { } = props;

  const { setSteps, setSkippableSteps } = useFormStepperDialogContext();

  useEffect(() => {
    setSteps(steps);
    setSkippableSteps(skippable);
  }, []);

  return (
    <StepperDialog>
      <FormStepper steps={steps} />
    </StepperDialog>
  );
};


export default MortgageNorwayFormStepperDialog;
