import { FC } from 'react';

import { Link, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

const GrConsent: FC = () => {

  return (
    <Typography
      sx={{ m: 0, textAlign: 'justify', fontSize: '0.9rem', lineHeight: '1.4' }}
    >
      <Trans>
        I consent to Zensum&apos;s
        {' '}
        <Link
          href="https://www.zensum.no/brukervilkar"
          target="_blank"
        >
          Terms of Service
        </Link>
        {' '}
        and to my debt data being retrieved and stored for 12 months in accordance with applicable law.
        The data is processed in line with Zensum&apos;s and
        {' '}
        <Link
          href="https://www.zensum.no/personvern"
          target="_blank"
        >
          Privacy Policy
        </Link>
        {' '}
        and analyzed for better loan opportunities.
        The consent is deleted on
        {' '}
        <Link
          href="http://gjeldsregisteret.com/"
          target="_blank"
        >
          Gjeldsregisteret
        </Link>
        .
      </Trans>
    </Typography>
  );
};


export default GrConsent;
