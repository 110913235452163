import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


interface LoanResponsibilityFieldProps {
  name: string;
}

const LoanResponsibilityField: FC<LoanResponsibilityFieldProps> = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();

  const value = getValueByPath<boolean>(values, name);
  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);

  return (
    <FieldSection
      description='Does the loan have multiple borrowers?'
      spacing={0}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(_e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);
            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <StyledToggleButton value={true}>
                {t('Yes, multiple')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <StyledToggleButton value={false}>
                {t('No, just me')}
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
    </FieldSection>
  );
};


export default LoanResponsibilityField;
