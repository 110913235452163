import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import LengthOfStay from 'src/types/Contact/LengthOfStay';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


interface LengthOfStayFieldProps {
  name: string;
  coApplicant?: boolean;
}

const LengthOfStayField: FC<LengthOfStayFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<LengthOfStay>(values, name);

  return (
    <FieldSection
      description={!coApplicant ? 'Specify how long you have lived in Norway' : 'Specify how long your co-applicant has lived in Norway'}
    >
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(_e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);
            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={4}
            >
              <StyledToggleButton value={LengthOfStay.LESS_THAN_A_YEAR}>
                {t('Less than a year')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <StyledToggleButton value={LengthOfStay.ONE_TO_THREE_YEARS}>
                {t('1 to 3 years')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <StyledToggleButton value={LengthOfStay.MORE_THAN_THREE_YEARS}>
                {t('More than 3 years')}
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler
        name={name}
      />
    </FieldSection>
  );
};


export default LengthOfStayField;
